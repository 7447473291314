import React from 'react'
import DashboardNav from '../Layout/DashboardNav'

function Profile() {
    return (
        <div>
            <DashboardNav />
            Profile Page

        </div>
    )
}

export default Profile