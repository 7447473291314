import React, { useState, useEffect } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import EventNoteIcon from '@material-ui/icons/EventNote';
import axios from 'axios';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: "20px",
    },
    note: {
        color: "red",
        fontWeight: "bold"
    },
    backgroundColor: {
        marginTop: "30px",
        backgroundColor: "white",
        borderRadius: "20px"
    },
    backgroundMargin: {
        margin: " 20px 50px"
    },
    subColor: {
        color: "#3e23ff",
        fontWeight: "bold"
    },
    sub: {
        marginRight: "20px"
    },
    subMargin: {
        marginTop: "20px"
    },
    Button1: {
        borderRadius: "20px",
        padding: "1px 20px",
        backgroundColor: "#3e23ff",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#3e23ff",
        }
    },
    // Buttonegde: {
    //     borderRadius: "1rem",
    //     padding: "0.7rem 0.7rem",
    //     backgroundColor: "#fff",
    //     color: "#3e23ff",
    //     "&:hover": {
    //         backgroundColor: "#3e23ff",
    //         color: "#fff",
    //     }
    // },
    Button2: {
        borderRadius: "20px",
        padding: "10px 20px",
        marginTop: "10px",
        backgroundColor: "#fff",
        color: "#3e23ff",
        "&:hover": {
            backgroundColor: "#3e23ff",
            color: "#fff",
        }
    },
    backgroundColor2: {
        marginTop: "30px",
        backgroundColor: "#d9dbdb",
        borderRadius: "20px"
    },
    background: {
        marginTop: '10px',
        textAlign: "center",
        fontSize: "400%",
        fontWeight: "bold"
    },
    background2: {
        textAlign: "center",
        color: "green",
        fontWeight: "bold",
        fontSize: "150%",
    },
    backgroundButton: {
        textAlign: "center",
        backgroundColor: "#3e23ff", 
        fontWeight: "bold",
        borderRadius: "20px",
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: "#3e23ff",
            color: "#fff",
        }
    },
    buttonMargin: {
        marginTop: "10%",
    },
    loadingColor: {
        margin: "20px 0",
    },
    loading: {
        margin: "0 4px",
        color: "black"
    }
    // subMag: {
    //     paddingRight: "12px"
    // }
}))

const AssessmentState = () => {

    const [assesmentState, setAssesmentState] = useState([])
    const [localData, setLocalData] = useState([])
    const [localToken, setLocalToken] = useState([])
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        async function getData() {
            if (window !== "undefined") {
                const uData = await localStorage.getItem('user');
                const userData = await uData ? JSON.parse(uData) : '';
                // console.log("FROM THE USE EFFECT BLOCK", userData.user._id)
                if (userData) {
                    setLocalData(userData.user._id);
                    setLocalToken(userData.accessToken);
                    // console.log("USER TOKEN", userData.accessToken)
                    // console.log("USER DATA", userData.user)
                }  
            }
        } getData()
        
    }, [])

    // console.log("ACCESS TOKEN", localToken)
    // console.log("USER ID", localData)


    useEffect(() => {
        const userId = localData
        console.log('USER ID IN EFFECT', userId)
        const userToken = localToken
        console.log('USER TOKEN IN EFFECT', userToken)
        function assessment() {
            if (localData) {
                console.log("LOCALDATA IN EFFECT", localData)
                axios.get(`https://iqstore.herokuapp.com/v1/assessments/users/${localData}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localToken}`
                        }
                    }).then((res) => {
                        setLoading(false)
                        console.log("ASSESSMENT DATA FROM ASSESSEMANT", res)
                        setAssesmentState(res.data.data.assessments)
                    })
                    .catch((error) => {
                        console.error("ASSESSMENT DATA", error)
                    })
            }
        }
        assessment()

    }, [localToken, localData])

    const classes = useStyles();
    return (<> {loading ? <Grid container sm={12} className={classes.loadingColor}>
        <Typography variant="body3" color="primary" className={classes.loading}>Fetching Data

        </Typography><Loader type="TailSpin" color="#3e23ff" height={20} width={30} />
    </Grid> : assesmentState.map((e) => (

        <Grid container sm={12} className={classes.backgroundColor} key={e._id}>
            <Grid item container sm={12} className={classes.backgroundMargin}>
                <Grid item sm={8}>
                    <Button variant="contained" className={classes.Button1}>{e.completed ? "completed" : "Not taken"}</Button>
                    <Typography className={classes.subMargin}><span className={classes.subColor}>Course:</span><span> {e.title}</span></Typography>
                    <Typography className={classes.subMargin}>
                        {e.instructions}
                    </Typography>
                    <Typography className={classes.subMargin}>
                        <span className={classes.sub}><span className={classes.subColor}>No of Questions:</span><span className={classes.subMag} >{e.noOfQuestions}</span></span>
                        <span className={classes.sub}><span className={classes.subColor}>No of Attempts Made:</span><span className={classes.subMag} >{e.attempts.noOfAttempts}</span></span>
                        <span className={classes.sub}><span className={classes.subColor}>No of Attempts Allowed:</span><span className={classes.subMag} >{e.noOfAllowedAttemps}</span></span>
                    </Typography>
                    <Button variant="contained" className={classes.Button2}>Get certified</Button>
                </Grid>
                <Grid item sm={2} />
                <Grid item sm={2}>
                    <Grid item container sm={12}>
                        <Grid item sm={12} className={classes.backgroundColor2}>
                            <Grid item sm={12}>
                                <Typography className={classes.background}>{e.cumulative.percentage_pass}%</Typography>
                                <Typography className={classes.background2}>{e.cumulative.comment}</Typography>
                            </Grid>
                            <Grid item container sm={12}>
                                <Grid item lg={1} />
                                <Grid item sm={10} className={classes.buttonMargin}>
                                    <Button variant="contained" className={classes.backgroundButton}><Typography variant="body2" style={{ color: "#fff" }}>Retake Test</Typography></Button>
                                </Grid>
                                <Grid item lg={1} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ))
    }

    </>)
}

export default AssessmentState