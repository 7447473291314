import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DashBoardHeader } from "../Layout/InputButton";
import DateRangeIcon from "@material-ui/icons/DateRange";

// import SewingImg from "../images/sewing.png";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import axios from "axios";
// import CoursesList from "../Components/Dashboard/CoursesList";
// import { Link } from "react-router-dom";

import DashboardNav from "../Layout/DashboardNav";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../theme";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#32CD32",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  bg: {
    backgroundColor: "#f2f2f2",
  },
  content: {
    margin: "20px",
  },
  logout: {
    color: "#fff",
    textDecoration: "none",
  },
  input: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "50px",
    padding: "20px",
    outline: "none",
    marginTop: "10px",
    width: "40%",
    position: "relative",
    boxShadow: "6px 7px 16px -1px rgba(0,0,0,0.53)",
  },

  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "15%",
    padding: "15px",
    border: "none",
    borderRadius: "50px",
    margin: "10px",
    position: "relative",
    left: "-70px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
  },
  buttonedge: {
    backgroundColor: "#FFF",
    color: "#000",
    width: "20%",
    padding: "10px",
    border: "none",
    borderRadius: "50px",
    margin: "25px",
    top: "-10px",
    marginTop: "8px",
    position: "relative",
    right: "-100px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "	#F8F8F8",
      color: "#000",
    },
  },
  searchInput: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "50px",
    marginBottom: "35px",
    left: "-45px",
    paddingLeft: "15px",
    outline: "none",
    width: "25%",
    position: "relative",
  },
  searchButton: {
    backgroundColor: "#C0C0C0",
    color: "#000",
    width: "15%",
    padding: "5px",
    border: "none",
    borderRadius: "50px",
    margin: "17px",
    top: "-16px",
    left: "-65px",
    position: "relative",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#696969",
      color: "#000",
    },
  },

  classCard: {
    marginTop: "40px",
    width: "100%",
  },

  cardStyle: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  img: {
    width: "16rem",
    maxHeight: "11rem",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  },

  iconButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  AssignIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },

  continueButton: {
    width: "80%",
    padding: "0.6rem",
    borderRadius: "50px",
    left: 0,
    backgroundColor: "#e0dbff",
    color: "#3E24FB",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      fontSize: "0.7rem",
    },
  },

  closedButton: {
    width: "20%",
    padding: "6px",
    borderRadius: "50px",
    position: "relative",
    margin: "10px",
    left: 0,
    backgroundColor: "#ffcccb",
    color: "red",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      left: "-20px",
      fontSize: "0.7rem",
    },
  },

  cardCourse: {
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },

  btnSection: {
    marginTop: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  searchSection: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  session: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      paddingLeft: "0.5rem",
    },
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(50),
    },

    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(-35),
    },
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: theme.spacing(-15),
    // },
  },
  paginationSection: {
    marginTop: "20px",
    width: "100%",
  },
  loading: {
    margin: "0 4px",
    color: "black",
  },
});

function Courses() {
  const classes = useStyles();

  const [userId, setUserId] = useState([]);
  const [accessToken, setAccessToken] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coursesState, setCoursesState] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    async function getData() {
      if (window !== "undefined") {
        const uData = await localStorage.getItem("user");
        const userData = (await uData) ? JSON.parse(uData) : "";
        if (userData) {
          setUserId(userData.user._id);
          setAccessToken(userData.accessToken);
        }
      }
    }
    getData();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/v1/courses/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        // console.log("COURSES DATA", res.data.data.courses);
        setCoursesState(res.data.data.courses);
        setPageIndex(res.data.data.pageIndex);
        setTotalDocuments(res.data.data.totalDocuments);
        setPageNumber(res.data.data.pageNumber);
      })
      .catch((error) => {
        console.error("COURSES DATA", error);
      });
  }, [accessToken, userId]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/v1/courses/users/${userId}?${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log("SEARCH DATA", res.data.data);
        setSearchValue(res.data.data.courses);
      })
      .catch((error) => {
        console.error("SEARCH DATA", error);
      });
  }, [accessToken, searchValue, userId]);

  const handleClick = (course) => {
    localStorage.setItem("courseId", course._id);
    localStorage.setItem("courseName", course.title);
  };
  return (
    <>
      <Grid container xs={12} className={classes.bg}>
        <DashboardNav />
        <Grid item xs={10} container className={classes.content}>
          <Grid item container style={{ width: "100%", padding: "20px" }}>
            <DashBoardHeader />
            <Grid item xs={10} container className={classes.btnSection}>
              <Button
                className={classes.button}
                style={{
                  margin: "25px",
                  padding: "10px",
                  width: "25%",
                  fontSize: 12,
                  fontWeight: "800",
                }}
              >
                Go to Assessment
              </Button>
              <Button
                className={classes.button}
                style={{
                  margin: "25px",
                  padding: "10px",
                  width: "35%",
                  backgroundColor: "#FFF",
                  color: "#000",
                }}
              >
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "800",
                    textDecoration: "none",
                    color: "#3e23ff",
                    paddingRight: "15px",
                  }}
                >
                  Stats
                  <AssignmentIcon
                    style={{
                      fontSize: "24px",
                      marginBottom: "-5px",
                      color: "#000",
                      paddingLeft: "3px",
                    }}
                  />
                </Typography>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "800",
                    textDecoration: "none",
                    color: "#000",
                    paddingLeft: "15px",
                  }}
                >
                  Pending Assessments (0)
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={10}
              container
              className={classes.searchSection}
              style={{ width: "100%" }}
            >
              <input
                type="text"
                id="searcher"
                className={classes.searchInput}
                placeholder="Search your Courses"
              />
              <Button className={classes.searchButton}>Search</Button>
              <Button className={classes.buttonedge}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "800",
                    textDecoration: "none",
                    color: "#000",
                    paddingRight: "15px",
                  }}
                >
                  <ArrowDropDownIcon
                    style={{
                      fontSize: "24px",
                      marginBottom: "-5px",
                      color: "#000",
                      paddingLeft: "3px",
                    }}
                  />
                  Filter
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <Grid container sm={12} className={classes.loadingColor}>
              <Typography
                variant="body3"
                color="primary"
                className={classes.loading}
              >
                Fetching Data
              </Typography>
              <Loader type="TailSpin" color="#3e23ff" height={20} width={30} />
            </Grid>
          ) : (
            coursesState.map((course) => (
              <Grid item className={classes.classCard} key={course._id}>
                <Paper elevation={3} style={{ padding: "20px" }}>
                  <Grid item container xs={12} className={classes.cardStyle}>
                    <Grid item xs={12} md={3}>
                      <img
                        src={course.image}
                        alt="Course Img"
                        className={classes.img}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={7}
                      direction="column"
                      style={{ paddingLeft: "20px" }}
                    >
                      <Grid item>
                        <Button className={classes.closedButton}>
                          In Progress
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.cardCourse}>
                        <Typography variant="h6">{course.title}</Typography>
                      </Grid>
                      <Grid xs={12} item className={classes.cardCourse}>
                        <Typography>Module 10</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.cardCourse}
                        style={{ display: "flex" }}
                      >
                        <DateRangeIcon />
                        <Typography className={classes.session}>
                          Session: 12th may to 3rd December
                        </Typography>
                      </Grid>
                      <Grid xs={12} item className={classes.cardCourse}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.iconButton}>
                      <AssignmentIcon className={classes.AssignIcon} />
                      <Button
                        className={classes.continueButton}
                        component={Link}
                        to="/coursesList"
                        onClick={() => handleClick(course)}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          )}

          <Grid
            item
            xs={12}
            md={10}
            container
            className={classes.paginationSection}
          >
            <div className={classes.root}>
              <Pagination
                count={pageNumber}
                // variant="outlined"
                // shape="rounded"
              />
            </div>
            <div className={classes.root}>
              <Typography style={{ marginLeft: theme.spacing(45) }}>
                Page: {pageIndex}, 1-3 of {totalDocuments} courses
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Courses;
