import React from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import EventNoteIcon from '@material-ui/icons/EventNote';


const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: "20px",
    },
    note: {
        color: "red",
        fontWeight: "bold"
    },
    backgroundColor: {
        marginTop: "30px",
        backgroundColor: "white",
        borderRadius: "20px"
    },
    backgroundMargin: {
        margin: " 20px 50px"
    },
    subColor: {
        color: "blue",
        fontWeight: "bold"
    },
    sub: {
        marginRight: "20px"
    },
    subMargin: {
        marginTop: "20px"
    },
    Button1: {
        borderRadius: "20px",
        padding: "1px 20px"
    },
    Button2: {
        borderRadius: "20px",
        padding: "10px 20px",
        marginTop: "10px"
    },
    backgroundColor2: {
        marginTop: "30px",
        backgroundColor: "#d9dbdb",
        borderRadius: "20px"
    },
    background: {
        marginTop: '10px',
        textAlign: "center",
        fontSize: "400%",
        fontWeight: "bold"
    },
    background2: {
        textAlign: "center",
        color: "green",
        fontWeight: "bold",
        fontSize: "150%",
    },
    backgroundButton: {
        textAlign: "center",
        color: "white",
        fontWeight: "bold",
        borderRadius: "20px",
        marginBottom: "10px"
    },
    buttonMargin: {
        marginTop: "10%",
    }
}))

const InprogressState = () => {
    const classes = useStyles();
    return (<Grid container sm={12} className={classes.backgroundColor}>
        <Grid item container sm={12} className={classes.backgroundMargin}>
            <Grid item sm={8}>
                <Button variant="contained" className={classes.Button1}>completed</Button>
                <Typography className={classes.subMargin}><span className={classes.subColor}>Course:</span><span> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></Typography>
                <Typography className={classes.subMargin}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a aliquam elit. Ut eget
                    justo sed diam bibendum accumsan in quis odio. Praesent sed congue ipsum.
                    volutpat.
                            </Typography>
                <Typography className={classes.subMargin}>
                    <span className={classes.sub}><span className={classes.subColor}>Section:</span><span>3</span></span>
                    <span className={classes.sub}><span className={classes.subColor}>Question:</span><span>49</span></span>
                    <span className={classes.sub}><span className={classes.subColor}>Time:</span><span>60 mins</span></span>
                </Typography>
                <Button variant="contained" className={classes.Button2}>Get certified</Button>
            </Grid>
            <Grid item sm={2} />
            <Grid item sm={2}>
                <Grid item container sm={12}>
                    <Grid item sm={12} className={classes.backgroundColor2}>
                        <Grid item sm={12}>
                            <Typography className={classes.background}><EventNoteIcon fontSize="inherit" /></Typography>
                            <Typography className={classes.background2}>Ready!!!</Typography>
                        </Grid>
                        <Grid item container sm={12}>
                            <Grid item lg={1} />
                            <Grid item sm={10} className={classes.buttonMargin}>
                                <Button variant="contained" className={classes.backgroundButton}><Typography variant="body2" color="primary">   Start Test..</Typography></Button>
                            </Grid>
                            <Grid item lg={1} />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>

    </Grid>)
}

export default InprogressState

