import React from "react";
import ivy from "../images/image.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Avatar, Grid } from "@material-ui/core";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    src: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png",
    label: "Kenneth Aladi | Nigeria",
    message: "It was really an Awesome Program and i Enjoyed every Bit of it",
  },
  {
    src: "https://image.flaticon.com/icons/png/128/3135/3135715.png",
    label: "Gabriel | Nigeria",
    message: "The Site is slack and i enjoyed learning from IQStore ",
  },
  {
    src:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkbeHX5-l4XrrWIimOxBDoqlf7tocTpv62fA&usqp=CAU",
    label: "Mr. Leeke, UK",
    message:
      "I have always imagined a plateform like this and here it is in our hands",
  },
  {
    src:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzNITZdNGcTi4sCN-ttc97d6HpuEB4Ext5FQ&usqp=CAU",
    label: "Kazeem, Las Vegas, United States",
    message:
      "Being part of the Team that developed this is one of the most awesome experience i ever had.",
  },
  {
    src:
      "https://img.favpng.com/2/24/0/computer-icons-avatar-user-profile-png-favpng-HPjiNes3x112h0jw38sbfpDY9.jpg",
    label: "Ayo, Serbia",
    message: "Everything about this program has been Awesome!!!",
  },
];

const useStyles = makeStyles((theme) => ({
  contain: {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ivy})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "3rem",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      margin: "0 auto",
    },
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    background: "none",
    color: "#fff",
    marginLeft: "0.8rem",
  },
  message: {
    height: "10rem",
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "100%",
    padding: "1rem",
  },

  arrowStyle: {
    backgroundColor: "#3e23ff",
    border: "0.2rem solid #fff",
    borderRadius: "50%",
    color: "#fff",
    padding: "0.4rem",
    height: "2rem",
    width: "2rem",
  },
}));

const Carousel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid xs={12} md={6} justify="center" className={classes.contain}>
      <div>
        <Typography variant="h4">What people say !!</Typography>
      </div>
      <div className={classes.root}>
        <div style={{ display: "flex", padding: "1rem" }}>
          <Avatar alt="Profile" src={tutorialSteps[activeStep].src} />
          <Paper square elevation={0} className={classes.header}>
            <Typography>{tutorialSteps[activeStep].label}</Typography>
          </Paper>
        </div>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Typography className={classes.message}>
                  {step.message}
                </Typography>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          className={classes.header}
          steps={maxSteps}
          position="static"
          color="secondary"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            <Button
              size="medium"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft className={classes.arrowStyle} />
              ) : (
                <KeyboardArrowRight className={classes.arrowStyle} />
              )}
            </Button>
          }
          backButton={
            <Button
              size="medium"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight className={classes.arrowStyle} />
              ) : (
                <KeyboardArrowLeft className={classes.arrowStyle} />
              )}
            </Button>
          }
        />
      </div>
    </Grid>
  );
};

export default Carousel;
