import React, { useState, useEffect } from "react";
import img from "../images/image.png";
import runlegs from "../images/runlegs.png";
import logo from "../images/logo.png";
import axios from "axios";
import swal from "sweetalert";

import {
  Grid,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 335,
    padding: "0.625rem",
    borderRadius: "5px",
    boxShadow: "0px 5px 10px #888888",
  },
  loading: {
    margin: "0 4px",
    color: "black",
  },
  root2: {
    maxWidth: "25rem",
    borderRadius: "5px",
    boxShadow: "0px 5px 10px #888888",
  },
  media2: {
    padding: "0px",
    paddingTop: "60%",
  },

  sale2: {
    color: "#000",
    // width: "180px",
    padding: "0.625rem",
    margin: "0.625rem",
  },
  media: {
    height: 0,
    paddingTop: "70.25%",
    borderRadius: "1.25rem",
  },

  sale: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "180px",
    padding: "0.625rem",
    margin: "0.625rem",
    borderRadius: "0.625rem",
    textAlign: "center",
    fontWeight: "bolder",
  },
  card: {
    maxWidth: "15.625rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1.25rem",
    height: "100%",
    borderRadius: "1.25rem",
    textAlign: "center",
    boxShadow: "5px 10px 8px #888888",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3.125rem",
    },
  },
}));

export const CardComponent = (props) => {
  const localToken = props.localToken;
  const courseID = props.coursesID;

  const classes = useStyles();

  const enrollCourse = (courseID) => {
    axios({
      method: "PUT",
      url: `https://iqstore.herokuapp.com/v1/courses/${courseID}/enroll`,
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
      // data: { localData }
    })
      .then((res) => {
        console.log("FROM ENROL BLOCK", res.data.message);
        swal({
          text: "Course successfully enrolled",
          icon: "success",
        });
      })
      .catch((err) => {
        // console.log("FROM ENROL BLOCK FAILED", err.message)
        swal({
          text: "You have already enrolled for this course!",
          icon: "info",
        });
      });
  };

  return (
    <div>
      <Card className={classes.root} style={{}}>
        <CardMedia image={props.img} className={classes.media} />
        {/* <img src={img} alt="card media" className={classes.media} /> */}
        <Typography className={classes.sale}>₦{props.price}</Typography>
        <CardContent>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {props.title}
          </Typography>
          <Typography variant="body2">{props.description}</Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="medium"
            style={{ borderBottom: "2px solid" }}
            onClick={() => enrollCourse(courseID)}
          >
            Enroll
          </Button>
          <IconButton
            style={{ marginLeft: "auto", backgroundColor: "#F8F8FF" }}
          >
            <BookmarkIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
};

export const CardHome = () => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.root} style={{}}>
        <CardMedia image={img} className={classes.media} />
        {/* <img src={img} alt="card media" className={classes.media} /> */}
        <Typography className={classes.sale}>₦3400</Typography>
        <CardContent>
          <Typography variant="body1">
            learn how to work with Adobe Xd and create Awesome mockups
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="medium"
            style={{ borderBottom: "2px solid" }}
          >
            Enroll
          </Button>
          <IconButton
            style={{ marginLeft: "auto", backgroundColor: "#F8F8FF" }}
          >
            <BookmarkIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
};

export const CardComponent2 = () => {
  const classes = useStyles();
  return (
    <div>
      {" "}
      <Card className={classes.root2}>
        <img
          src={logo}
          alt="logo"
          style={{ position: "absolute", width: "80px" }}
        />
        <CardMedia image={runlegs} className={classes.media2} />
        {/* <img src={img} alt="card media" className={classes.media} /> */}
        <Typography className={classes.sale2}>
          21 fact to build your brain power
        </Typography>
        <CardContent>
          <Typography variant="body1">
            It has roots in a piece of classical Latin literature literature,
            discovered the undoubtable source.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="medium"
            style={{ borderBottom: "2px solid" }}
          >
            Read more
          </Button>
          {/* <Button
            style={{
              marginLeft: "auto",
              backgroundColor: "#F8F8FF",
              color: "#ff726b",
            }}
          >
            Tech | Inspiration | Science
          </Button> */}
          <ButtonGroup
            variant="text"
            aria-label="text primary button group"
            style={{
              marginLeft: "auto",
              backgroundColor: "#F8F8FF",
            }}
          >
            <Button
              style={{
                color: "#ff726b",
              }}
            >
              Tech
            </Button>
            <Button
              style={{
                color: "#ff726b",
              }}
            >
              Inspiration
            </Button>
            <Button
              style={{
                color: "#ff726b",
              }}
            >
              Science
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </div>
  );
};

export const CourseCard = (props) => {
  const classes = useStyles();
  return (
    <Card
      className={classes.card}
      style={{ borderBottom: "10px solid #3e23ff" }}
    >
      {props.img}
      <CardContent>
        <Typography gutterBottom variant="h6" component="h4">
          {props.heading}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};
