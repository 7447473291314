import React from "react";
import logo from "../images/logo.png";
import ivy from "../images/image.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PinterestIcon from "@material-ui/icons/Pinterest";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  icons: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      justifyItem: "space-around",
      padding: "1rem",
    },
  },

  links: {
    [theme.breakpoints.down("sm")]: {
      padding: "3rem",
    },
  },

  contact: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 3rem",
    },
  },

  course: {
    display: "flex",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  enrollBtn: {
    backgroundColor: "#ff0000",
    color: "#fff",
    width: "10rem",
    padding: "0.2rem",
    marginTop: "0.625rem",
    borderRadius: "0.625rem",
    textAlign: "center",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        backgroundColor: "#343a48",
        color: "#fff",
        display: "flex",
        justifyContent: "space-evenly",
        padding: "1.5rem",
      }}
    >
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={2}>
        <div className={classes.logo}>
          <img src={logo} alt="Iqstore Logo" style={{ width: "4rem" }} />
          <h3 style={{ paddingTop: "0.7rem" }}>IQstore</h3>
        </div>
        <div className={classes.icons}>
          <FacebookIcon />
          <TwitterIcon />
          <LinkedInIcon />
          <PinterestIcon />
        </div>
      </Grid>
      <Grid item xs={6} md={2} className={classes.links}>
        <h3>Links</h3>

        <ul>
          <li>Home</li>
          <li>Courses</li>
          <li>Articules</li>
          <li>About</li>
        </ul>
      </Grid>
      <Grid item xs={6} md={2} className={classes.links}>
        <h3>Support</h3>

        <ul>
          <li>FAQ</li>
          <li>Features</li>
          <li>Contacts</li>
        </ul>
      </Grid>
      <Grid item xs={12} md={2} className={classes.contact}>
        <h3>Contact us</h3>
        <ul>
          <li>+88 123456789</li>
          <li>kenneth@gmail.com</li>
          <li>Africa City</li>
        </ul>
      </Grid>
      <Grid item xs={12} md={3} className={classes.course}>
        <div>
          <img
            src={ivy}
            alt="footerImage"
            style={{
              width: "10rem",
              height: "8rem",
              borderRadius: "1rem",
            }}
          />
        </div>
        <Grid item style={{ paddingLeft: "1rem" }}>
          <Typography variant="h5">UX Design Bootcamp</Typography>
          <Typography variant="body1">
            Learn how to work with adobe XD and create awesome UX designs and
            MockUps
          </Typography>
          <Button className={classes.enrollBtn}>Enroll</Button>
        </Grid>
      </Grid>
      {/* <Grid item xs={0} md={1} /> */}
    </Grid>
  );
};

export default Footer;
