import React, { useState, useEffect } from "react";
import ivy from "../../images/image.png";
import NavBar from "../../Layout/NavBar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, useHistory } from "react-router-dom"
import JWT from "jsonwebtoken"
import swal from "sweetalert"
//import 'react-toastify/dist/ReactToastify.min.css';

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import dots from "../../images/dots_1.png";

const useStyles = makeStyles({
    bg: {
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ivy})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        margin: "0px",
        // paddingTop: "70px",
        color: "#fff",
        fontFamily: `Poppins, sans-serif`,
    },

    dots: {
        float: "left",
        marginTop: "20%",
        marginLeft: "-18px",
        margin: "50px",
        height: "38%"
    },

    dot: {
        float: "right",
        marginRight: "-12px",
        margin: "50px",
        marginTop: "20%",
        height: "38%"
    },

    form: {
        backgroundColor: "#343a48",
        marginTop: "80px",
        paddingTop: "10%",
        borderRadius: "10px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
    },
    input: {
        width: "90%",
        borderRadius: "50px",
        outline: "none",
        backgroundColor: "#fff",
        border: "none",
        padding: "20px",
        margin: "10px",
    },
    button: {
        backgroundColor: "#3e23ff",
        color: "#fff",
        width: "100%",
        padding: "15px",
        borderRadius: "50px",
        margin: "5px",
        "&:hover": {
            backgroundColor: "#3e10ff",
            color: "#fff",
        },
    },
    buttonTop: {
        border: "2px solid #fff",
        borderRadius: "50px",
        padding: "15px",
        margin: "20px",
        color: "#fff",
    },
});


function Activate({ match }) {
    const classes = useStyles();
    const history = useHistory()

    const [values, setValues] = useState({
        fullName: "",
        token: "",
        show: true,
        buttonText: "Activate account"
    })


    const { fullName, token, show, buttonText } = values
    useEffect(() => {
        let token = match.params.token
        //let { fullName } = JWT.decode(token)
        // console.log(token)
        if (token) {
            setValues({
                ...values, token
            })

        }
    }, [])

    const clickSubmit = e => {
        e.preventDefault()
        setValues({ ...values, buttonText: " Activating..." });
        console.log(typeof token)
        axios.post("https://iqstore.herokuapp.com/v1/auths/users/activateEmail", {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
        }).then((res) => {
            console.log("ACCOUNT ACTIVATION SUCESS", res.data.message)
            setValues({ ...values, buttonText: "Activated" })
            history.push('/login')
            swal({
                title: res.data.message,
                icon: "success",
                buttonText: "ok",
                timer: "2000"
            });
        }).catch((err) => {
            console.log("ACCOUNT ACTIVATION ERROR", err.response.data)
            setValues({ ...values, buttonText: "Activate Error" })
            swal({
                text: "Activate Account Error ",
                icon: "error",
                buttonText: "ok",
                timer: "2000"
            });
        })
    }

    return (
        <Grid container className={classes.bg}>
            <ToastContainer />
            <NavBar />
            <Grid item xs={0} sm={3} />
            <Grid item xs={12} sm={6} className={classes.form}>
                <img className={classes.dots} src={dots} alt="dots" />
                <form className={classes.root} noValidate autoComplete="off">
                    <Typography variant="h3">{`Welcome ${fullName}, click to activate you account`}</Typography>

                    <Button onClick={clickSubmit} className={classes.button}>{buttonText}</Button>
                </form>
                <img className={classes.dot} src={dots} alt="dots" />
            </Grid>
            <Grid item xs={0} sm={2} />
        </Grid>
    );
}

export default Activate;
