import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";


import { Classroom, Explore, Help, Dashboard, Courses, Profile, Settings } from "./Pages"
import Assignment from "../src/Components/Dashboard/Assignment"
import QNdA from "../src/Components/Dashboard/QNdA"
import Certificate from "../src/Components/Dashboard/Certificate" 
import ViewModule from "../src/Components/Modules/ViewModule"
import Home from "./Pages/Home";
import Signup from "./Components/Auth/signup";
import accountActivate from "./Components/Auth/activate";
import Login from "./Pages/login";
import CoursesList from "../src/Components/Dashboard/CoursesList";
import PrivateRoute from "../src/Components/Auth/PrivateRoute"
//import NavBar from "./Layout/NavBar";

const Router = () => {
  return (
    <BrowserRouter>
      {/* <NavBar /> */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/signup" component={Signup} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/classroom" component={Classroom} />
        <PrivateRoute path="/explore" component={Explore} />
        <Route path="/help" component={Help} />
        <Route path="/assignment" component={Assignment} />
        <Route path="/QndA" component={QNdA} />
        <Route path="/coursesList" component={CoursesList} />
        <Route path="/viewModule" component={ViewModule} />
        <Route path="/certificate" component={Certificate} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/courses" component={Courses} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/settings" component={Settings} />
        <Route path="/account-activate/:token" component={accountActivate} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
