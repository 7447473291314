import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core';

import bg from "../../images/BG.png";

const useStyles = makeStyles((theme) => ({
    sectionMargin: {
        marginTop: "40px"
    },
    section2Bg: {
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f9ffff",
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        }
    },
    sectionText: {
        textAlign: 'center',
        margin: "40% 10% 50% 0",
        // marginBottom: "50%",
        fontSize: "1rem",
        width: "100%"

    },
}))


function Certificate() {
    const classes = useStyles();
    return (
        <Grid container sm={12} className={classes.sectionMargin}>
            <Grid container item sm={12}>
                <Grid item sm={12} className={classes.section2Bg} >
                    <Typography className={classes.sectionText}>NO CERTIFICATE EARNED YET!!!</Typography>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Certificate