import React, { useState, useEffect } from "react";
import axios from "axios";
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Button, Typography, List, ListItem, ListItemIcon, Avatar } from "@material-ui/core";
import EventNoteIcon from '@material-ui/icons/EventNote';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import swal from "sweetalert"
import { makeStyles } from "@material-ui/core/styles";
import Moment from 'react-moment';
import ShowMoreText from 'react-show-more-text';

import { Paper } from "@material-ui/core";

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { Link } from "react-router-dom";
import Modal from "react-modal"
import Face from "../../../../images/face.png";
import MenuListComposition from "./AnswerButton"
import NativeSelect from "@material-ui/core/NativeSelect";
import ModalForm from './ModalAskQuestion';
import FavoriteIcon from '@material-ui/icons/Favorite';


import CloseIcon from '@material-ui/icons/Close';
import { questionID } from "./QndAState"


const useStyles = makeStyles((theme) => ({
    sendButton: {
        // float: "right",
        // backgroundColor: "#3e23ff",
        color: "grey",
        padding: "0 0",
        borderRadius: "50px",
        // margin: "10px",
        "&:hover": {
            // backgroundColor: "#3e10ff",
            color: "black",
        }
    },
    age: {
        fontWeight: "bold"
    },
    textarea: {
        margin: "200px"
    },
    formContainer: {
        marginTop: "40px",
        width: "100%",
        maxWidth: "900px",
        margin: "0 auto",
    },
    formBody: {
        display: "flex",
        // width: "100%",
    },
    largeIcon: {
        // width: "10px",
        // height: "30px",
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "20px",
        '& > *': {
            margin: theme.spacing(1),
        },

    },
    bg: {
        backgroundColor: "#f2f2f2",
    },
    content: {
        margin: "20px",
    },
    Buttonegde: {
        borderRadius: "10px"
    },
    Buttonegde2: {
        borderRadius: "20px",
    },
    button: {
        padding: "0 0 0 5px",
        midWidth: "100%",
        marginTop: "5px",
        // border: "0.1px solid grey",
        // borderRadius: "20px",
        borderTop: "0 solid grey",
        borderRadius: "20px",
        borderLeft: "0",
        borderRight: "0",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        borderBottom: "0px solid grey",
    },
    colorBackground: {
        // backgroundColor: "green",
        width: "100%",
        midWidth: "20px",
        backgroundColor: "#fff",
        borderRadius: "20px",

    },
    textMargin: {
        margin: " 20px 0px",
    },
    text: {
        padding: "2px 20px"
    },
    text2: {
        padding: "2px 2px"
    },
    textColor: {
        color: "black",
        marginLeft: "5px"
    },
    large: {
        border: "3px solid #3e23ff",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        backgroundColor: "white",
        '&:hover': {
            color: "#3e23ff",
        },
    },

    space: {
        marginTop: "10px",
    },
    answerFont: {
        fontSize: "12px"
    },
    likeSize: {
        width: "10px",
        borderRadius: "50px",
    },
    white: {
        color: "white",
        backgroundColor: "#3e23ff",
        borderRadius: "50px",
        '&:hover': {
            color: "black",
            backgroundColor: "#3e23ff",
            borderRadius: "20px"
        },
    },
    loadingColor: {
        margin: "20px 0",
    },
    loading: {
        margin: "0 4px",
        color: "black"
    },
    closeButton: {
        color: "grey",
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "0.5rem 0.5rem 0.5rem 0.1rem",
        borderRadius: "70px",
        // margin: "10px",
        "&:hover": {
            backgroundColor: "#eb3a34",
            color: "#fff",
        }
    },
    answerContainer: {
        midWidth: "100px"
    },
    wrapperCon: {
        overflow: "scroll",
        whiteSpace: "nowrap",
        height: '100%',
    },
    wrapper: {
        margin: "5px 0",
        // width: "10vw",
        // display: "flex",
        // flexWrap: "wrap",
        // midWidth: "960px",
        // borderRadius: "20px"
        // width: "200px",
        // height: "200px",
        // border: "1px solid #c3c3c3",
        // display: "flex",
        // flexWrap: "wrap",
    },
    innerWrapper: {

        // width: "50px",
        // height: "50px",
    },
    wrapper2: {
        margin: "5px 0px",
        width: "60vw",
        midWidth: "960px",
    },
    margin: {
        margin: "0 20px"
    },
    black: {
        color: "black"
    },
}),
    

)


const Form = {
    // textAlign: 'center',
    // fontSize: "0.2rem",
    // fontWeight: "bold",
}

const Input2 = {
    minWidth: "53vw",
    // height: "1vw",
    padding: "0.6rem",
    margin: "0.5rem",
    borderTop: "0",
    borderRadius: "20px",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: "6px solid #3e23ff",
    fontSize: "1rem",
    fontWeight: "400",
    outline: "none",
}

const ModalAnswerQuestionForm = ({ queID, getCourseID }) => {
    // console.log("FROM MAQ", getCourseID)
    const classes = useStyles();
    const [localData, setLocalData] = useState([])
    const [getAnswers, setGetAnswers] = useState([])
    const [localToken, setLocalToken] = useState([])
    const [likes, setLikes] = useState("")
    const [like, setLike] = useState(false)
    const [answer, setAnswer] = useState({ text: "" })
    const { text } = answer

    const handleChange = (e) => {
        setAnswer({ ...answer, text: e.target.value })
    }

    useEffect(() => {
        function fetchLocalData() {
            if (window !== "undefined") {
                const uData = localStorage.getItem('user');
                const userData = uData ? JSON.parse(uData) : '';
                // console.log("FROM THE USE EFFECT BLOCK", userData.user._id)
                if (userData) {
                    setLocalData(userData.user._id)
                    setLocalToken(userData.accessToken);
                } else {
                    console.log("PROBLEM GETTING THE USER ID")
                }
            }
        }
        fetchLocalData()
    }, [localToken])

    useEffect(() => {
        //CHECK
        console.log("TOKEN FROM ANSWER BLOCK", localToken)
        function getAllAnswers() {
            if (localToken) {
                axios.get(`https://iqstore.herokuapp.com/v1/questionsAnswers/questions/${queID}/answers`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localToken}`
                        }
                    })
                    .then((res) => {
                        console.log("ANSWERS DATA", res.data.data.answers)
                        setGetAnswers(res.data.data.answers)
                       
                    })
                    .catch((error) => {
                        console.error("ANSWERS DATA", error)
                    })
            }

        } getAllAnswers()

    }, [localToken, likes, answer])

    const sendAnswer = (e) => {
        //CHECK
        e.preventDefault();
        if (queID) {

            axios({
                method: "POST",
                url: `https://iqstore.herokuapp.com/v1/questionsAnswers/questions/${queID}/answers`,
                headers: {
                    'Authorization': `Bearer ${localToken}`
                },
                data: { text }
            })
                .then((res) => {
                    if (res) {
                        console.log("QUESTION CREATION DATA", res)
                        setAnswer({ text: "" })
                        // console.log("THE ASSESSMENT DATA", res.data.data.assessments)
                    }
                })
                .catch((error) => {
                    console.error("ASSESSMENT DATA", error)
                })
        }
    }
    const handleClick = (id) => {
        if (like == false) {
            LikePost(id)
            setLike(!like)

        } else if (like == true) {
            disLikePost(id)
            setLike(!like)
        }

    }
    const LikePost = (id) => {
        console.log("Post clicked", id)
        axios({
            method: "PUT",
            url: `https://iqstore.herokuapp.com/v1/questionsAnswers/answers/${id}/like`,
            headers: {
                'Authorization': `Bearer ${localToken}`
            },
            data: { localData }
        }).then((res) => {
            // console.log("LIKE QUESTION".res)
            setLikes("resetLikeState")
        }).catch((err) => {
            // console.log("ERROR LIKE QUESTION".err)
        })
    }

    const disLikePost = (id) => {
        // console.log("Post clicked", id)
        axios({
            method: "PUT",
            url: `https://iqstore.herokuapp.com/v1/questionsAnswers/answers/${id}/unlike`,
            headers: {
                'Authorization': `Bearer ${localToken}`
            },
            data: { localData }
        }).then((res) => {
            // console.log("DISLIKE QUESTION".res)
            setLikes("reloadDislike")
        }).catch((err) => {
            // console.log("ERROR LIKE QUESTION".err)
        })
    }


    // const Data = [{
    //     id: "fueueibjdjdlk",
    //     img: "hdhdkdks",
    //     name: "Ay",
    //     question: "How to setup PHP dataBASE",
    //     datePosted: "20/14/2021",
    //     comment: "lorem ipsum dolor sit ametconsectetur adipiscing elit.Donec a aliquam elit.Ut eget justo sed diam bibendum accumsan in quis odio Praesent sed congue ipsum.Cras vehicula miut justo aliquam tempus.Sed hendrerit fermentum est.Cras tincidunt risus varius neque egestasvolutpat",
    //     noOfAnswers: 10,
    //     noOfLikes: 45
    // },
    // {
    //     id: "hdkdkuoiuoleklel",
    //     img: "jbkdjeoeioe",
    //     name: "Gabriel",
    //     question: "How to setup my native server with Linux xentrl ",
    //     datePosted: "2/1/2021",
    //     comment: "lorem ipsum dolor sit ametconsectetur adipiscing elit.Donec a aliquam elit.Ut eget justo sed diam bibendum accumsan in quis odio Praesent sed congue ipsum.Cras vehicula miut justo aliquam tempus.Sed hendrerit fermentum est.Cras tincidunt risus varius neque egestasvolutpat",
    //     noOfAnswers: 201,
    //     noOfLikes: 82
    // }
    // ]
    // const FORM = <Paper variant="outlined" className={classes.wrapper}>
    //     <div className={classes.innerWrapper}>
    //         <Grid container lg={12} sm={12} className={classes.button}>
    //             <Grid item container sm={12} className={classes.colorBackground} >
    //                 <Grid item sm={12} className={classes.textMargin}>
    //                     <List>
    //                         <Link to="/profile" className={classes.link}>
    //                             <ListItem button>
    //                                 <ListItemIcon>
    //                                     <Avatar
    //                                         alt="Remy Sharp"
    //                                         src={Face}
    //                                         className={classes.large}
    //                                     />
    //                                 </ListItemIcon>
    //                                 <Grid item container sm={12} disableFocusRipple={true}>
    //                                     <Grid item sm={4}>
    //                                         <Typography>
    //                                             KAZEEM BOLAJI
    //                                                         </Typography>
    //                                     </Grid>
    //                                     <Grid item sm={12} />
    //                                     <Grid item sm={4}>
    //                                         <Typography variant="body2"><Moment fromNow ago>45</Moment> <span className={classes.ago}>ago</span></Typography>
    //                                     </Grid>
    //                                 </Grid>
    //                             </ListItem>
    //                         </Link>
    //                     </List>

    //                     <Grid>
    //                         <Typography>
    //                             HELLO HOW WAS YOUR NIGHT
    //                                         </Typography>
    //                     </Grid>
    //                     <Grid item container sm={12} className={classes.space}>
    //                         <Grid item sm={1} >
    //                             <Button focusRipple={true} disableTouchRipple={true} disableRipple={true} startIcon={<ThumbUpAltIcon />}><Typography className={classes.answerFont}>0</Typography></Button>
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     </div>
    // </Paper>

    // const imgLink =
    //     "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

    return (
        <> {/* <div className={classes.wrapperCon}> */}
            {/* {console.log("GETTING ANSWERS", getAnswers)} */}
            {getAnswers.map((e) => {
                return <div>
                    <Paper variant="outlined" className={classes.wrapper} style={{
                        width: "100%", borderRadius: "10px", borderTop: "0",
                        borderLeft: "0",
                        borderRight: "0",
                        borderBottom: "2px solid grey"
                    }}>
                        <div className={classes.innerWrapper}>
                            <Grid container lg={12} sm={12} className={classes.button} key={e._id}>

                                <Grid item container sm={12} className={classes.colorBackground} >
                                    <Grid item sm={12} className={classes.textMargin}>
                                        <List>
                                            <div to="/profile" className={classes.link} disableFocusRipple={true}>
                                                <ListItem disableFocusRipple={true}>
                                                    <ListItemIcon disableFocusRipple={true}>
                                                        <Avatar
                                                            disableFocusRipple={true}
                                                            alt="Remy Sharp"
                                                            src={Face}
                                                            className={classes.large}
                                                        />
                                                    </ListItemIcon>
                                                    <Grid item container sm={12} disableFocusRipple={true}>
                                                        <Grid item sm={4}>
                                                            <Typography>
                                                                {e.author.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sm={12} />
                                                        <Grid item sm={4}>
                                                            {/* <Typography variant="body2"><Moment fromNow ago>{e.datePosted}</Moment> ago</Typography> */}
                                                            <Typography variant="body2"><Moment fromNow ago>{e.datePosted}</Moment> <span className={classes.ago}>ago</span></Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </div>
                                        </List>

                                        <Grid>
                                            <Typography>
                                                {e.text}
                                                {/* vjgsfvhjfbkfsbkfskfsfn */}
                                            </Typography>
                                        </Grid>
                                        <Grid item container sm={12} className={classes.space}>
                                            <Grid item sm={1} >
                                                {/* <Button centerRipple={true} className={classes.white}><ThumbUpAltIcon fontSize="small" ></ThumbUpAltIcon></Button> */}
                                                <Button focusRipple={true} disableTouchRipple={true} style={{ color: e.likes > 0 ? "#fe3266" : "grey" }} onClick={() => (handleClick(e._id))} disableRipple={true} startIcon={<ThumbUpAltIcon />}><Typography className={classes.answerFont}><span className={classes.black}>{e.likes}</span></Typography></Button>
                                            </Grid>
                                            {/* <Grid item sm={2}> */}
                                            {/* <MenuListComposition /> */}
                                            {/* <Button startIcon={<QuestionAnswerIcon />} onClick={() => { setAnswer(true) }}><Typography className={classes.answerFont}>30 Answer</Typography> */}
                                            {/* <Button startIcon={<QuestionAnswerIcon />} ><Typography className={classes.answerFont}>30 Answer</Typography>
                                                </Button> */}
                                            {/* </Grid> */}
                                            {/* <Grid item sm={9} /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                        {/* <Grid container wrap="nowrap" className={classes.wrapper2} >
                            <Grid item>
                                <Avatar alt="Remy Sharp" src={imgLink} className={classes.margin} />
                            </Grid>
                            <Grid item xs>
                                <h4 > {e.name}</h4>
                                <p >
                                    {e.datePosted}
                                </p>
                                <p>
                                    {e.question}
                                </p>
                                <p >
                                    {e.comment}
                                </p>
                               <Button focusRipple={true} disableTouchRipple={true} disableRipple={true} startIcon={<ThumbUpAltIcon />}><Typography className={classes.answerFont}>0</Typography></Button>

                            </Grid>
                        </Grid> */}
                    </Paper>
                </div>

            })}
            <Grid container xs={12}>
                <Grid item xs={false} md={1} />
                <Grid item xs={12} md={7} >
                    <div className={classes.formContainer}>
                        <form style={Form} noValidate autoComplete="off" className={classes.formBody} >


                            <textarea
                                // id="ans"
                                type="text"
                                placeholder="Answer Question"
                                required
                                style={Input2}
                                onChange={handleChange}
                                value={text}
                            />
                            <IconButton onClick={sendAnswer} className={`${classes.sendButton} ${classes.largeIcon} ${classes.formBody}`}> <SendIcon />
                            </IconButton>

                        </form>
                    </div>
                </Grid>
            </Grid>
            {/* </div> */}


        </>);
};

export default ModalAnswerQuestionForm;

