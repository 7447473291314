import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Grid, Typography, List, ListItem, ListItemIcon, Avatar } from "@material-ui/core";
import Face from "../../../../images/face.png";
import { Link } from "react-router-dom";

import AssignmentState from "../Assessment/AssessState"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    paperBody: {
        display: "block",
        flexWrap: "wrap",
        backgroundColor: "white",
        marginBottom: "0px",
        width: "70%",
        margin: "auto",
        borderRadius: "20px",
        position: "relative",
        top: "20rem"
    },
    PaperContent: {
        marginBottom: "90px"
    },
    answerFont: {
        fontSize: "12px"
    },
    bg: {
        backgroundColor: "#f2f2f2",
    },
    content: {
        marginBottom: "10px",
        borderBottom: "2px solid grey",
        paddingBottom: "5px",
    },
    Buttonegde: {
        borderRadius: "10px"
    },
    Buttonegde2: {
        borderRadius: "20px",
    },
    // button: {
    //     marginTop: "30px",
    // },
    text: {
        padding: "2px 20px"
    },
    text2: {
        padding: "2px 2px"
    },
    // textColor: {
    //     color: "black",
    //     marginLeft: "5px"
    // },
    large: {
        border: "3px solid #3e23ff",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        backgroundColor: "white",
        '&:hover': {
            color: "#3e23ff",
        },
    },
    colorBackground: {
        backgroundColor: "white",
        borderRadius: "20px"
    },
    textMargin: {
        margin: "0 20px"
    },
    space: {
        marginTop: "10px",
    },
    answerFont: {
        fontSize: "12px"
    },
    likeSize: {
        width: "10px",
        borderRadius: "50px",
    },
    white: {
        color: "white",
        backgroundColor: "#3e23ff",
        borderRadius: "50px",
        '&:hover': {
            color: "black",
            backgroundColor: "#3e23ff",
            borderRadius: "20px"
        },
    },
    borderCard: {
        // width: "50%",
        textAlign: "center",
        borderRadius: "20px"
    },
}));

function MenuListComposition() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const Data = [{
        id: "fueueibjdjdlk",
        img: "hdhdkdks",
        name: "Ay",
        question: "How to setup PHP dataBASE",
        datePosted: "20/14/2021",
        comment: "lorem ipsum dolor sit ametconsectetur adipiscing elit.Donec a aliquam elit.Ut eget justo sed diam bibendum accumsan in quis odio Praesent sed congue ipsum.Cras vehicula miut justo aliquam tempus.Sed hendrerit fermentum est.Cras tincidunt risus varius neque egestasvolutpat",
        noOfAnswers: 10,
        noOfLikes: 45
    },
    {
        id: "hdkdkuoiuoleklel",
        img: "jbkdjeoeioe",
        name: "Gabriel",
        question: "How to setup my native server with Linux xentrl ",
        datePosted: "2/1/2021",
        comment: "lorem ipsum dolor sit ametconsectetur adipiscing elit.Donec a aliquam elit.Ut eget justo sed diam bibendum accumsan in quis odio Praesent sed congue ipsum.Cras vehicula miut justo aliquam tempus.Sed hendrerit fermentum est.Cras tincidunt risus varius neque egestasvolutpat",
        noOfAnswers: 201,
        noOfLikes: 82
    },
    {
        id: "gdhdksksisisk",
        img: "bhshhbsbihsibsjk",
        name: "Ken",
        question: "How to create a toggle button",
        datePosted: "2/1/2001",
        comment: "lorem ipsum dolor sit ametconsectetur adipiscing elit.Donec a aliquam elit.Ut eget justo sed diam bibendum accumsan in quis odio Praesent sed congue ipsum.Cras vehicula miut justo aliquam tempus.Sed hendrerit fermentum est.Cras tincidunt risus varius neque egestasvolutpat",
        noOfAnswers: 12,
        noOfLikes: 105

    },
    ]
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    startIcon={<QuestionAnswerIcon />}><Typography className={classes.answerFont}>12 Answer</Typography>
                </Button>

                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper className={classes.paperBody}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        className={classes.PaperContent}
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >{Data.map((e) => {
                                        return <Grid container sm={12} className={classes.borderCard}>
                                            <Grid container sm={12} className={classes.button}>
                                                <Grid item container sm={12} className={classes.colorBackground} >
                                                    <Grid item sm={12} className={classes.textMargin}>
                                                        <Grid>
                                                            <List>
                                                                <Link to="/profile" className={classes.link}>
                                                                    <ListItem button>
                                                                        <ListItemIcon>
                                                                            <Avatar
                                                                                alt="Remy Sharp"
                                                                                src={Face}
                                                                                className={classes.large}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <Grid item container sm={12} disableFocusRipple={true}>
                                                                            <Grid item sm={4}>
                                                                                <Typography>{e.name}</Typography>
                                                                            </Grid>
                                                                            <Grid item sm={12} />
                                                                            <Grid item sm={4}>
                                                                                <Typography variant="body2">{e.datePosted}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItem>
                                                                </Link>
                                                            </List>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography className={classes.content}>
                                                                {e.comment}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    })}
                                    </MenuList>
                                    {/* <MenuList
                                        // className={classes.PaperContent}
                                        // autoFocusItem={open}
                                        // id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {Data.map((e) => {
                                            return <MenuItem className={classes.PaperContent} key={e.id} >
                                                {<Grid container sm={12} className={classes.borderCard}>
                                                    <Grid container sm={12} className={classes.button}>
                                                        <Grid item container sm={12} className={classes.colorBackground} >
                                                            <Grid item sm={12} className={classes.textMargin}>
                                                                <Grid>
                                                                    <List>
                                                                        <Link to="/profile" className={classes.link}>
                                                                            <ListItem button>
                                                                                <ListItemIcon>
                                                                                    <Avatar
                                                                                        alt="Remy Sharp"
                                                                                        src={Face}
                                                                                        className={classes.large}
                                                                                    />
                                                                                </ListItemIcon>
                                                                                <Grid item container sm={12} disableFocusRipple={true}>
                                                                                    <Grid item sm={4}>
                                                                                        <Typography>{e.name}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item sm={12} />
                                                                                    <Grid item sm={4}>
                                                                                        <Typography variant="body2">{e.datePosted}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </ListItem>
                                                                        </Link>
                                                                    </List>
                                                                </Grid>
                                                                <Grid>
                                                                    <Typography>
                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a aliquam elit. Ut eget
                                                                        justo sed diam bibendum accumsan in quis odio. Praesent sed congue ipsum. Cras vehicula mi
                                                                        ut justo aliquam tempus. Sed hendrerit fermentum est. Cras tincidunt risus varius neque egestas
                                                                        volutpat. Quisque in nunc at libero posuere fermentum. Ut pharetra viverra lectus a congue.
                                                                        Vivamus ac nisl mollis, dignissim velit vel, tincidunt arcu. Aenean scelerisque ipsum id quam
                                                                        porta pulvinar. Mauris fringilla sapien nisl, ut tincidunt neque porttitor sit amet. Cras
                                                                        lacinia, enim in laoreet pretium, ex felis condimentum ex, in eleifend risus mi eget arcu.
                                                                        Nam iaculis erat sit amet nunc dignissim, eu fermentum massa mollis.
                                                        </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            </MenuItem>
                                        })}
                                    </MenuList> */}
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

export default MenuListComposition