import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import EmailIcon from '@material-ui/icons/Email';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import SewingImg from "../../images/sewing.png";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "15px",
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    sectionMargin: {
        marginTop: "40px"
    },
    section2Bg: {
        height: "55vh",
        backgroundColor: "#f9ffff",
        borderRadius: "20px",
    },
    sectionText: {
        // height: "50vh",
        marginTop: "50px",
        marginBottom: "0",
        marginLeft: "20px",
        marginRight: "0"

    },
    profileInput: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: "40px",
    },
    img: {
        width: "15rem",
        height: "11rem",
        borderRadius: "10px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    settingsHeader: {
        margin: "15px"
    },
    downloadbutton: {
        padding: "10px 50px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        color: "grey",
        "&:hover": {
            backgroundColor: "#fff",
            color: "black",
        },
    },
    emailButton: {
        padding: "10px 50px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        color: "grey",
        "&:hover": {
            backgroundColor: "#fff",
            color: "black",
        },
    },
    RsetPButton: {
        padding: "10px 30px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        color: "grey",
        "&:hover": {
            backgroundColor: "#fff",
            color: "black",
        },
    },
    saveButton: {
        padding: "10px 50px",
        textAlign: "center",
        backgroundColor: "#3e23ff",
        color: "#fff",
        borderRadius: "20px",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#3e23ff",
        },
    },
}))


function Profile() {
    const classes = useStyles();

    const [localData, setLocalData] = useState([])
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState()

    useEffect(() => {
        async function fetchLocalData() {
            if (window !== "undefined") {
                const uData = await localStorage.getItem('user');
                const userData = await uData ? JSON.parse(uData) : '';
            if (userData) {
                setLocalData(userData.user)
                } else {
                    console.log("PROBLEM GETTING THE USER ID")
                }
            }
        }
        fetchLocalData()
    }, [])
    console.log("FORM SETTINGS", localData)

    const userData = localData
    if (localData !== undefined && localData !== []) {
        console.log("POLL NAMES", userData.fullName)
        // setFirstName(userData.fullName)
    }
// useEffect(() => {
//         if (localData !== undefined) {
//             console.log("POLL NAMES", localData.fullName)
//             setFirstName(localData.fullName)
//             // const grr = fullname.split(" ")
//             // // console.log(grr[0] + "and" + grr[1])
//             // console.log("POLLINGRE", grr[0])
//             // setFirstName(grr[0])
//         }
//     }, [])

    // const nj = firstName

    // console.log("POLLINGREFIRST", firstName)
    const firstNameInput = "KAZEEM"
    const lastNameInput = "ERINFOLAMI"
    const emailInput = "ERINFOLAMIBOLAJI2010@GMAIL.COM"
    const phoneNumInput = "+2349077334922"

    return (
        <Grid container sm={12} className={classes.sectionMargin}>
            <Grid container item sm={12}>
                <Grid item sm={12} className={classes.section2Bg} >
                    <Grid className={classes.sectionText}>
                        <Grid sm={12} className={classes.settingsHeader}>
                            General settings
                        </Grid>
                        <Grid container item sm={12}>
                            <Grid item sm={3}>
                                <img
                                    src={SewingImg}
                                    alt="A man sewing"
                                    className={classes.img}
                                />
                            </Grid>
                            <Grid item sm={9}>
                                <form className={classes.root}>

                                    <TextField
                                        id="outlined-helperText"
                                        margin="dense"
                                        label="first Name"
                                        // defaultValue={firstNameInput}
                                        variant="outlined"
                                        className={classes.profileInput}
                                    />
                                    <TextField
                                        id="outlined-helperText"
                                        label="Last Name"
                                        // defaultValue={lastNameInput}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.profileInput}
                                    />
                                    <TextField
                                        id="outlined-helperText"
                                        label="Email"
                                        // defaultValue={emailInput}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.profileInput}
                                    />
                                    <TextField
                                        label="Phone No"
                                        // defaultValue={phoneNumInput}
                                        margin="dense"
                                        variant="outlined"
                                        style={{ borderRadius: "50px" }}
                                        className={classes.profileInput}
                                    />
                                </form>
                            </Grid>

                        </Grid>
                        <Grid container item sm={12}>
                            <Grid item sm={3}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.downloadbutton}
                                    startIcon={<BackupOutlinedIcon />}
                                >
                                    Change image
                                    </Button>
                            </Grid>
                            <Grid item sm={3}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.emailButton}
                                    startIcon={<EmailIcon />}
                                >
                                    Change email
                                    </Button>
                            </Grid>
                            <Grid item sm={3}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.RsetPButton}
                                    startIcon={<SecurityOutlinedIcon />}
                                >
                                    reset password
                                    </Button>
                            </Grid>
                            <Grid item sm={3}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.saveButton}
                                    endIcon={<SaveAltOutlinedIcon />}
                                >
                                    save
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Profile
