import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import swal from "sweetalert"


const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: "#3e23ff",
        color: "#fff",
        padding: "1rem 3rem",
        borderRadius: "50px",
        margin: "10px",
        "&:hover": {
            backgroundColor: "#3e10ff",
            color: "#fff",
        }
    },
    textarea: {
        margin: "200px"
    }

})
)
const Form = {
    // textAlign: 'center',
    // fontSize: "0.2rem",
    // fontWeight: "bold",
}
const Input = {
    width: "57vw",
    padding: "0.6rem",
    marginTop: "2rem",
    // borderTop: "0",
    // // borderRight: "0",
    // // borderLeft: "0",
    fontSize: "1rem",
    fontWeight: "300",
    outline: "none",
    borderRadius: "10px",
    borderBottom: "6px solid #3e23ff",
    // border: "100px 20px 30px 100px",
    // backgroundColor: "red"
}

const Input2 = {
    width: "57vw",
    height: "150px",
    padding: "0.6rem",
    margin: "0.5rem",
    borderTop: "0",
    borderRadius: "20px",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: "6px solid #3e23ff",
    fontSize: "1rem",
    fontWeight: "300",
    outline: "none",
}

const ModalForm = () => {
    const classes = useStyles();

    const [localData, setLocalData] = useState([])
    const [localToken, setLocalToken] = useState([])
    const [courseID, setCourseID] = useState([])
    const [reset, setReset] = useState("")
    const [values, setValues] = useState({
        title: "",
        description: "",
        buttonText: "Send",
    });

    const {
        title,
        description,
        buttonText,
    } = values;

    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };


    useEffect(() => {
        function fetchLocalData() {
            if (window !== "undefined") {
                const uData = localStorage.getItem('user');
                const userData = uData ? JSON.parse(uData) : '';
                // console.log("FROM THE USE EFFECT BLOCK", userData.user._id)
                if (userData) {
                    setLocalData(userData.user._id)
                    setLocalToken(userData.accessToken);
                } else {
                    console.log("PROBLEM GETTING THE USER ID")
                }
            }
        }
        fetchLocalData()
    }, [])

    // console.log("USER TOKEN", localToken)
    // console.log('USER ID', localData)
    // console.log('COURSE ID', courseID)

    useEffect(() => {

        // if () { 

        // }
        if (localData) {
            function assessment() {
                axios.get(`https://iqstore.herokuapp.com/v1/assessments/users/${localData}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localToken}`
                        }
                    })
                    .then((res) => {
                        if (res) {
                            console.log("COURSE DATA FROM THE QNDA ASSESSMENT EFFECT", res.data.data.assessments[1].courseId)
                            setCourseID(res.data.data.assessments[0].courseId)
                            // console.log("THE ASSESSMENT DATA", res.data.data.assessments)
                        }
                    })
                    .catch((error) => {
                        console.error("ASSESSMENT DATA", error)
                    })
            }
            assessment()

        }
    }, [values, reset])

    const onSubmit = (e) => {
        e.preventDefault();
        setValues({ ...values, buttonText: "Sending..." });
        console.log({
            title,
            description
        });
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/v1/questionsAnswers/courses/${courseID}/questions`,
            data: { title, description },

            headers: {
                'Authorization': `Bearer ${localToken}`
            }

        },

        ).then((res) => {
            console.log("FROM CREATE QUESTION", res)
            setValues({ ...values, buttonText: "Sent" })
            setReset("reset")
            // window.location.reload(true)

        })
            .catch((error) => {
                setValues({ ...values, buttonText: "Sending failed" })

                console.error("CREATE QUESTION ERROR", error)
            })
    }
    return (

        <Grid container xs={12}>
            <Grid item xs={false} md={1} />
            <Grid item xs={12} md={7} >
                <form style={Form} noValidate autoComplete="off" >
                    <Typography variant="h5">Ask a Question</Typography>
                    <input
                        id="title"
                        type="text"
                        placeholder="Title"
                        required
                        style={Input}
                        onChange={handleChange("title")}
                        value={title}
                    />
                    <textarea
                        id="description"
                        type="text"
                        placeholder="Description"
                        required
                        style={Input2}
                        onChange={handleChange("description")}
                        value={description}
                    />
                    <Button onClick={onSubmit} endIcon={<SendIcon />} className={classes.button}>
                        {buttonText}
                    </Button>
                </form>
            </Grid>
            <Grid item xs={0} md={3} />
        </Grid>
    );
};

export default ModalForm;
