import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import ivy from "../../images/image.png";
import { ToastContainer, toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { RadioGroup, Radio } from "@material-ui/core";
import dots from "../../images/dots_1.png";
import NavBar from "../../Layout/NavBar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AOIdata from "./data";
import { useHistory, Redirect } from "react-router-dom";
import { isAuth } from "../../Components/Auth/Storage";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  bg: {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ivy})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    margin: "0px",
    color: "#fff",
    fontFamily: `Poppins, sans-serif`,
    // height: "100vh",
    // maxHeight: "120%",
  },
  dots: {
    float: "left",
    marginTop: "20%",
    marginLeft: "-1.125rem",
    margin: "3.125rem",
    height: "38%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      margin: 0,
    },
  },

  dot: {
    float: "right",
    marginRight: "-0.75rem",
    margin: "3.125rem",
    marginTop: "20%",
    height: "38%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  form: {
    backgroundColor: "#343a48",
    marginTop: "6%",
    marginLeft: "12%",
    // marginBottom: "5%",
    paddingTop: "6%",
    paddingBottom: "3%",
    borderRadius: "0.625rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6.25rem",
      margin: "0%",
      marginBottom: "15%",
      paddingBottom: "1.5rem",
      height: "auto",
    },
  },

  input: {
    width: "90%",
    borderRadius: "3.125rem",
    outline: "none",
    backgroundColor: "#fff",
    border: "none",
    padding: "1.25rem",
    margin: "0.625rem",
  },
  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "90%",
    padding: "1.25rem",
    outline: "none",
    borderRadius: "3.125rem",
    margin: "0.625rem",
    "&:hover": {
      backgroundColor: "#3e10ff",
      color: "#fff",
    },
  },
  buttonTop: {
    border: "2px solid #fff",
    borderRadius: "3.125rem",
    padding: "1rem",
    margin: "1.25rem",
    color: "#fff",
  },
  radio: {
    marginLeft: "25%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  color: {
    color: "#fff",
  },
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const style = {
  chips: {
    background: "#3e23ff",
    cursor: "pointer",
  },
  searchBox: {
    border: "none",
    "border-bottom": "1px solid #3e23ff",
    "border-radius": "0px",
    cursor: "pointer",
  },
  multiselectContainer: {
    color: "#3e23ff",
    cursor: "pointer",
  },
  option: {
    color: "black",
    overflow: "scroll",
    margin: "0",
    padding: "3px",
    outline: "none",
    border: "1px inset grey",
  },
};
const Signup = () => {
  // const history = useHistory()

  const classes = useStyles();
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    mobile: "",
    areaOfInterest: [],
    password: "",
    confirmPassword: "",
    role: "",
    buttonText: "Signup",
  });

  const {
    fullName,
    email,
    password,
    confirmPassword,
    mobile,
    areaOfInterest,
    role,
    buttonText,
  } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [selectData, setselectData] = useState(AOIdata);
  const onSelect = (d) => {
    setValues({ ...values, areaOfInterest: d });
  };
  const onRemove = (d) => {
    setValues({ ...values, areaOfInterest: d });
  };

  const rollChange = (event) => {
    setValues({ ...values, role: event.target.value });
  };
  const rollChangeTutor = (event) => {
    setValues({ ...values, role: event.target.value });
  };

  const clickSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, buttonText: "Submitting..." });
    console.log({
      fullName,
      email,
      password,
      confirmPassword,
      mobile,
      areaOfInterest,
      role,
    });
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/v1/auths/users/signup`,
      data: {
        email,
        fullName,
        password,
        confirmPassword,
        mobile,
        areaOfInterest,
        role,
      },
    })
      .then((res) => {
        console.log("SIGNUP SUCESSFULLY MADE", res.data.message);
        setValues({
          ...values,
          fullName: "",
          email: "",
          password: "",
          confirmPassword: "",
          role: "",
          mobile: "",
          areaOfInterest: "",
          buttonText: "Submitted",
        });
        // toast.success(res.data.message)
        swal({
          text: `email has been sent to ${email}, visit to verify`,
          icon: "success",
          button: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          setValues({ ...values, buttonText: "Try again" });
          swal({
            text: err.response.data.message,
            icon: "error",
            button: false,
          });
        } else if (err.request) {
          swal({
            text: "Connection Error",
            icon: "info",
            button: false,
          });
          setValues({
            ...values,
            buttonText: "Try Again",
          });
        } else {
          swal({
            text: "Something went wrong",
            icon: "info",
            button: "Try Again",
          });
          setValues({
            ...values,
            buttonText: "Try Again",
          });
        }
      });
    // .catch((err) => {
    // // console.log("SIGNUP ERROR", err.response.data.details.errors[0].message)
    // setValues({ ...values, buttonText: "Try again" })
    // toast.error(err.response.data.message)
    // });
  };

  return (
    <Grid container className={classes.bg} xs={12}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NavBar />

      {isAuth() ? <Redirect to="/dashboard" /> : <Redirect to="/signup" />}
      <Grid item xs={false} md={1} />
      <Grid item xs={12} md={7} className={classes.form}>
        <img className={classes.dots} src={dots} alt="dots" />
        <form noValidate autoComplete="off">
          <Typography variant="h3">REGISTER</Typography>
          <input
            type="text"
            placeholder="Full Name"
            required
            className={classes.input}
            onChange={handleChange("fullName")}
            value={fullName}
          />
          <input
            type="email"
            placeholder="Email"
            required
            className={classes.input}
            onChange={handleChange("email")}
            value={email}
          />
          <input
            type="mobile"
            placeholder="mobile"
            required
            className={classes.input}
            onChange={handleChange("mobile")}
            value={mobile}
          />
          <input
            type="password"
            placeholder="Password(8 characters minimum)"
            minlength="8"
            required
            className={classes.input}
            onChange={handleChange("password")}
            value={password}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            minlength="8"
            required
            className={classes.input}
            onChange={handleChange("confirmPassword")}
            value={confirmPassword}
          />
          <RadioGroup
            className={classes.radio}
            row
            aria-label="role"
            name="role"
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              label="Student"
              labelPlacement="end"
              onClick={rollChange}
              value="student"
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label="Tutor"
              labelPlacement="end"
              onClick={rollChangeTutor}
              value="tutor"
            />
          </RadioGroup>
          <div>
            <div>
              <Typography component="legend" variant="h6">
                Area of interest
              </Typography>
              <Multiselect
                options={selectData}
                displayValue="AOI"
                onSelect={onSelect}
                onRemove={onRemove}
                placeholder={"Add"}
                selectionLimit="4"
                style={style}
              />
            </div>
          </div>

          <Button onClick={clickSubmit} className={classes.button}>
            {buttonText}
          </Button>
        </form>
        <img className={classes.dot} src={dots} alt="dots" />
      </Grid>
      <Grid item xs={0} md={3} />
    </Grid>
  );
};

export default Signup;
