import cookie from "js-cookie"

//set cookie when a user sucessfuly login
export const setCookie = (name, value) => {
    if (window !== "undefined") {
        cookie.set(name, value, { expires: 7 })
    }
}

//remove from cookie when a user has been signed out
export const removeCookie = (name) => {
    if (window !== "undefined") {
        cookie.remove(name, { expires: 7 })
    }
}

//get from cookie such as stored token when making a token req to the server
export const getCookie = (name) => {
    if (window !== "undefined") {
        return cookie.get(name)
    }
}

//set in localstorage when a user sucessfuly login
export const setLocalStorage = (name, value) => {
    if (window !== "undefined") {
        localStorage.setItem(name, JSON.stringify(value))
    }
}

//remove localstorage when a user has been signed out
export const removeLocalStorage = (name) => {
    if (window !== "undefined") {
        localStorage.removeItem(name)
    }
}

export const getLocalStorage = () => {
    if (window !== "undefined") {
        const uData = localStorage.getItem("user")
        const userData = uData !== null ? JSON.parse(uData) : []
        console.log("FROM LOCAL STR", userData.user._id)
    }
}

//the above methods are Helper methods
//Created a method will help save the user data in the Cookie and local storage
//authenticate user by passing data to the cookie and localstorage when signed in
export const authenticate = (res, next) => {
    console.log('AUTHENTICATE', res);
    setCookie("token", res.data.data.accessToken)
    setLocalStorage("user", res.data.data)
    next()
}

//Created a method to access user info from localstorage
export const isAuth = () => {
    if (window !== "undefined") {
        const cookieChecked = getCookie("token")
        if (cookieChecked) {
            if (localStorage.getItem("user")) {
                return JSON.parse(localStorage.getItem("user"))
            } else {
                return false
            }
        }
    }
}

//RemoveCookie
export const signoutCookie = (next) => {
    removeCookie("token")
    removeLocalStorage("user")
    next()
}