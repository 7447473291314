import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  // Paper,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  // IconButton,
} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DashboardNav from "../../Layout/DashboardNav";
import { DashBoardHeader } from "../../Layout/InputButton";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import NoteIcon from "@material-ui/icons/Note";
import LinkIcon from "@material-ui/icons/Link";
import Forward10Icon from "@material-ui/icons/Forward10";
import PauseIcon from "@material-ui/icons/Pause";
import Replay10Icon from "@material-ui/icons/Replay10";
import GetAppIcon from "@material-ui/icons/GetApp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import WarningIcon from "@material-ui/icons/Warning";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { Link } from "react-router-dom";
import clsx from "clsx";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#f2f2f2",
    // height: "100%",
  },
  content: {
    margin: "20px",
  },
  logout: {
    color: "#fff",
    textDecoration: "none",
  },
  input: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "50px",
    padding: "20px",
    outline: "none",
    marginTop: "10px",
    width: "40%",
    position: "relative",
    boxShadow: "6px 7px 16px -1px rgba(0,0,0,0.53)",
  },

  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "15%",
    padding: "15px",
    border: "none",
    borderRadius: "50px",
    margin: "10px",
    position: "relative",
    left: "-70px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
  },

  askQuestionBtn: {
    backgroundColor: "#FFF",
    color: "#000",
    textDecoration: "none",
    border: "none",
    borderRadius: "50px",
    margin: "10px",
    marginTop: "-5px",
    padding: "5px",
    width: "18%",
    marginRight: "-5em",
    position: "relative",
    left: "-70px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  moduleName: {
    fontWeight: "600",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  contentSection: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },

  videoSelectSection: {
    width: "100%",
    height: "60%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    height: "90%",
    borderRadius: "1.25rem",
    textAlign: "left",
  },
  spanner: {
    marginBottom: 6,
  },

  btnContent: {
    textDecoration: "none",
    fontSize: "12px",
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(9),
      fontWeight: "bolder",
    },
  },
  completedIcon: {
    fontSize: "18px",
    marginLeft: "8px",
    marginTop: "-6px",
    marginBottom: "-6px",
    color: "#00cc00",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",

      fontSize: theme.typography.pxToRem(10),
      // fontWeight: "bolder",
    },
  },

  playButton: {
    fontSize: "18px",
    marginRight: "8px",
    marginTop: "-6px",
    marginBottom: "-6px",
    color: "#808080",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      marginBottom: "0",
      fontSize: theme.typography.pxToRem(10),
    },
  },
  Buttonegde2: {
    width: "80%",
    borderRadius: "1.25rem",
  },
  Buttonegde: {
    borderRadius: "1rem",
    padding: "0.7rem 0.7rem",
    textAlign: "left",
    display: "flex",
    alignItems: "left",
  },

  body2: {
    fontSize: "0.85rem",
    fontWeight: "bold",
  },
  btnBack: {
    backgroundColor: "#FFF",
    color: "#000",
    textDecoration: "none",
    marginTop: "-5px",
    padding: "10px",
    width: "18%",
    marginLeft: "25em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "12em",
      width: "100%",
      left: "30px",
    },
  },
  root: {
    "& > *": {
      marginRight: theme.spacing(40),
    },
    [theme.breakpoints.down("sm")]: {
      // marginRight: theme.spacing(15),
      // display: "flex",
      // flexDirection: "row",
      // width: "100%",
      // fontSize: "10px",
      // justifyContent: "flex-start",
      // alignItems: "flex-start",
      display: "none",
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 70,
    width: 70,
  },
  skipIcon: {
    height: 50,
    width: 50,
    marginRight: 100,
    marginLeft: 100,
  },
  nextBtn: {
    marginLeft: "30%",
    paddingBottom: "-20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10%",
    },
  },
  prevBtn: {
    marginLeft: "10px",
    marginRight: "65%",
    paddingBottom: "-20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "45%",
    },
  },
}));

function ViewModule(youtubeID) {
  const classes = useStyles();
  // const theme = useTheme();
  const [user, changeUser] = useState("content");
  // const [userId, setUserId] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [courseId, setCourseId] = useState();
  const [contentId, setContentId] = useState();
  const [moduleId, setModuleId] = useState();
  const [module, setModule] = useState();
  const [contentTitle, setContentTitle] = useState();
  const [youtubeId, setYoutubeId] = useState("");
  const [moduleContent, setModuleContent] = useState([]);
  // const [video, setVideo] = useState();

  const handleClick = (contentId, contentTitle) => {
    setContentId(contentId);
    setContentTitle(contentTitle);
    console.log(contentId);
  };

  useEffect(() => {
      const uData = localStorage.getItem("user");
      // const sentCourseId = localStorage.getItem("courseId");
      // const sentContentId = localStorage.getItem("contentId");
      // // const sentContentTitle = localStorage.getItem("contentTitle");
      // const sentModuleId = localStorage.getItem("moduleId");
      // const sentModule = localStorage.getItem("module");
      // const sentModuleContent = await JSON.parse(
      //   localStorage.getItem("moduleContent")
      // );
      const userData = (uData) ? JSON.parse(uData) : "";
      if (userData) {
        // setUserId(userData.user._id);
        // setAccessToken(userData.accessToken);
        // setCourseId(sentCourseId);
        // setContentId(sentContentId);
        // setContentTitle(sentContentTitle);
        // setModuleId(sentModuleId);
        // setModule(sentModule);
        setModuleContent(JSON.parse(
        localStorage.getItem("moduleContent")
        ));
        const courseId = localStorage.getItem("courseId");
        const contentId = localStorage.getItem("contentId");
        const moduleId = localStorage.getItem("moduleId");
        console.log("PART OF PAYLOAD", courseId, contentId, moduleId, userData)
        axios
            .get(
                `${process.env.REACT_APP_API}/v1/courses/${courseId}/modules/${moduleId}/contents/${contentId}`,
                {
                  headers: {
                    Authorization: `Bearer ${userData.accessToken}`,
                  },
                }
            )
            .then((res) => {
              // setLoading(false);
              setYoutubeId(res.data.data.content.youtubeId);
            })
            .catch((error) => {
              console.error("Module content Error", error);
            });
      }
  }, []);

  return (
    <Grid container xs={12} className={classes.bg}>
      <DashboardNav />
      <Grid item xs={10} container className={classes.content}>
        <Grid item container style={{ width: "100%", padding: "20px" }}>
          <DashBoardHeader />
        </Grid>
        <Grid
          item
          xs={12}
          container
          style={{ marginTop: "20px", width: "100%" }}
        >
          <Typography className={classes.moduleName}>{module}</Typography>
          <Button
            className={clsx(classes.btnBack, classes.button)}
            component={Link}
            to="/courses"
            onClick={() => {
              localStorage.removeItem("moduleId");
              localStorage.removeItem("module");
              localStorage.removeItem("moduleContent");
              localStorage.removeItem("contentId");
              localStorage.removeItem("contentTitle");
            }}
            style={{}}
          >
            <Typography
              style={{
                textDecoration: "none",
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              Back to Course
            </Typography>
          </Button>
          <Button
            startIcon={<QuestionAnswerIcon />}
            className={classes.askQuestionBtn}
            component={Link}
            to="/classroom"
          >
            <Typography
              style={{
                textDecoration: "none",
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              Ask Questions
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={12} container className={classes.contentSection}>
          <Grid
            item
            xs={12}
            md={7}
            container
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Card className={classes.card}>
              <CardContent>
                <span className={classes.spanner}>
                  <NoteIcon className={classes.playButton} />
                  <Button className={classes.btnContent}>{contentTitle}</Button>
                  <DoneAllIcon className={classes.completedIcon} />
                </span>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                  fullWidth="false"
                  className={classes.Buttonegde2}
                >
                  <Button
                    className={classes.Buttonegde}
                    startIcon={<NoteIcon />}
                    variant="contained"
                    onClick={() => changeUser("content")}
                    style={{ backgroundColor: "#3e23ff", color: "#FFF" }}
                  >
                    <Typography inherit="left" className={classes.body2}>
                      Content
                    </Typography>
                  </Button>
                  <Button
                    className={classes.Buttonegde}
                    startIcon={<LinkIcon />}
                    variant="contained"
                    onClick={() => changeUser("resources")}
                  >
                    <Typography className={classes.body2}>Resources</Typography>
                  </Button>
                </ButtonGroup>
                {/*<CardMedia*/}
                {/*  component="video"*/}
                {/*  alt="content video"*/}
                {/*  style={{*/}
                {/*    margin: "20px",*/}
                {/*    padding: "10px",*/}
                {/*    textDecoration: "none",*/}
                {/*  }}*/}
                {/*  height="350"*/}
                {/*  src={`${process.env.REACT_APP_API}/v1/courses/${courseId}/modules/${moduleId}/contents/${contentId}`}*/}
                {/*  title="course content video"*/}
                {/*  visibility="visible"*/}
                {/*  autoPlay*/}
                {/*  controls*/}
                {/*/>*/}
                <div style={{
                        margin: "20px",
                        padding: "10px",
                        width: "100%",
                        textDecoration: "none",
                      }}>
                  <iframe
                      width="100%"
                      height="350px"
                      src= {`https://www.youtube.com/embed/${youtubeId}`}
                      title="course content video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  ></iframe>
                </div>
                {/* <div className={classes.controls}>
                  <IconButton aria-label="previous">
                    {theme.direction === "rtl" ? (
                      <Forward10Icon className={classes.skipIcon} />
                    ) : (
                      <Replay10Icon className={classes.skipIcon} />
                    )}
                  </IconButton>
                  <IconButton aria-label="play/pause">
                    <PauseIcon className={classes.playIcon} />
                  </IconButton>
                  <IconButton aria-label="next">
                    {theme.direction === "rtl" ? (
                      <Replay10Icon className={classes.skipIcon} />
                    ) : (
                      <Forward10Icon className={classes.skipIcon} />
                    )}
                  </IconButton>
                </div> */}
                {/* <div className={classes.root}>
                  <Button>Mark Completed</Button>
                  <Button startIcon={<GetAppIcon />} style={{ margin: "15px" }}>
                    Download
                  </Button>
                </div> */}
              </CardActions>
            </Card>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "5px",
                width: "100%",
              }}
            >
              <Card className={classes.card}>
                <CardActions>
                  <Button
                    startIcon={<NavigateBeforeIcon />}
                    className={classes.prevBtn}
                  >
                    Prev
                  </Button>
                  <Button
                    endIcon={<NavigateNextIcon />}
                    className={classes.nextBtn}
                  >
                    Next
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            className={classes.videoSelectSection}
          >
            <Card className={classes.card}>
              <CardActions>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: 30,
                    width: "50%",
                    marginTop: "-8px",
                  }}
                >
                  <Typography
                    style={{
                      textDecoration: "none",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    Topics
                  </Typography>
                </Button>
              </CardActions>
              {moduleContent.map((content) => {
                return (
                  <CardContent>
                    <div className={classes.flex}>
                      <span className={classes.spanner}>
                        <PlayCircleFilledIcon className={classes.playButton} />
                        <Button
                          className={classes.btnContent}
                          onClick={() => handleClick(content.id, content.title)}
                        >
                          {content.title} ({content.duration})
                        </Button>
                        <DoneAllIcon className={classes.completedIcon} />
                      </span>
                      {/* <span className={classes.spanner}>
                        <PlayCircleFilledIcon
                          style={{
                            fontSize: "18px",
                            marginRight: "8px",
                            marginTop: "-6px",
                            marginBottom: "-6px",
                            color: "#808080",
                          }}
                        />
                        <a
                          style={{ textDecoration: "none", fontSize: "12px" }}
                          href="#"
                        >
                          The basic of Android debugging (32 mins)
                        </a>
                        <TimelapseIcon
                          style={{
                            fontSize: "18px",
                            marginLeft: "8px",
                            marginTop: "-6px",
                            marginBottom: "-6px",
                            color: "#00cc00",
                          }}
                        />
                      </span>
                      <span className={classes.spanner}>
                        <PlayCircleFilledIcon
                          style={{
                            fontSize: "18px",
                            marginRight: "8px",
                            marginTop: "-6px",
                            marginBottom: "-6px",
                            color: "#808080",
                          }}
                        />
                        <a
                          style={{ textDecoration: "none", fontSize: "12px" }}
                          href="#"
                        >
                          Module Dialog and Notification Bar
                        </a>
                        <TimelapseIcon
                          style={{
                            fontSize: "18px",
                            marginLeft: "8px",
                            marginTop: "-6px",
                            marginBottom: "-6px",
                            color: "#00cc00",
                          }}
                        />
                      </span>
                      <span className={classes.spanner}>
                        <li>
                          <a
                            style={{ textDecoration: "none", fontSize: "12px" }}
                            href="#"
                          >
                            Test (Summary of module 1)
                          </a>
                          <WarningIcon
                            style={{
                              fontSize: "18px",
                              marginLeft: "8px",
                              marginTop: "-6px",
                              marginBottom: "-6px",
                              color: "red",
                            }}
                          />
                        </li>
                      </span>
                      <span className={classes.spanner}>
                        <NoteIcon
                          style={{
                            fontSize: "18px",
                            marginRight: "8px",
                            marginTop: "-6px",
                            marginBottom: "-6px",
                            color: "#808080",
                          }}
                        />
                        <a
                          style={{ textDecoration: "none", fontSize: "12px" }}
                          href="#"
                        >
                          Research paper on android users (6 mins)
                        </a>
                        <DoneAllIcon
                          style={{
                            fontSize: "18px",
                            marginLeft: "8px",
                            marginTop: "-6px",
                            marginBottom: "-6px",
                            color: "#00cc00",
                          }}
                        />
                      </span> */}
                    </div>
                  </CardContent>
                );
              })}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ViewModule;
