import React from 'react'
import Assignment from "./Assignment"
import Certificate from "./Certificate"
import QNdA from "./QNdA"


const components = {
    assignment: Assignment,
    certificate: Certificate,
    QNdA: QNdA,
   
}

function DynamicComponent(props) {

    const SelectUser = components[props.user]
    return <SelectUser />
}

export default DynamicComponent