import React, { useState } from "react";
import {
  Drawer,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  Divider,
  Switch,
  FormGroup,
  Avatar,
  Paper,
  Button,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import ViewQuiltOutlinedIcon from "@material-ui/icons/ViewQuiltOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SettingsIcon from "@material-ui/icons/Settings";
import SchoolIcon from "@material-ui/icons/School";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import SecurityIcon from "@material-ui/icons/Security";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink, Link, withRouter } from "react-router-dom";
import Face from "../images/face.png";
import logo from "../images/logo2.png";

//
import clsx from "clsx";
import { signoutCookie } from "../Components/Auth/Storage";

const drawerWidth = "180px";

const useStyle = makeStyles((theme) => ({
  move: {
    width: "150px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  appBar: {
    Index: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      display: "none",
      position: "absolute",
      left: "-100px",
      transition: "10s",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    position: "absolute",
    top: "10px",
    left: "8px",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      // display: "block",
    },
  },
  logout: {
    color: "#fff",
    textDecoration: "none",
    marginRight: "1rem",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  large: {
    border: "4px solid #3e23ff",
  },

  //Tutor Switch
  root: {
    width: 62,
    height: 30,
    padding: 0,
    margin: theme.spacing(2),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(33px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3e23ff",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#3e23ff",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 28,
    height: 28,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  show: { display: "none" },
  focusVisible: {},

  verification: {
    width: "35%",
    position: "fixed",
    top: "10rem",
    left: "35rem",
    padding: "2rem",
    zIndex: 2,
    borderRadius: "1rem",
  },

  verifyGrid: {
    position: "absolute",
    width: "100%",
    height: "70rem",
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
    zIndex: 2,
  },
  input: {
    width: "100%",
    borderRadius: "3.125rem",
    outline: "none",
    backgroundColor: "#D9DDDC",
    padding: "1rem",
    marginTop: "1rem",
  },
  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "100%",
    padding: "0.9rem",
    outline: "none",
    "&:hover": {
      backgroundColor: "#3e10ff",
      color: "#fff",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  //Mobile View
  menuButtonMobile: {
    display: "flex",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
      width: "100px",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      flex: "1",
    },
  },

  gridMobile: {
    display: "block",
    transition: "10s",
    left: "0",
  },
}));

function DashboardNav({ match }) {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState();
  const [display, setDisplay] = useState({ display: "none" });
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const closeMobileMenu = () => setIsOpen(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      setDisplay({ display: "none" });
    } else {
      setDisplay({ display: "block" });
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const activeNav = (path) => {
    if (match.path === path) {
      return { color: "blue" };
    } else {
      return { color: "active" };
    }
  };

  const classes = useStyle();
  return (
    <Grid item xs={12} md={1}>
      <Grid item container xs={12} className={classes.menuButtonMobile}>
        <Grid item xs={1} />
        <NavLink to="/" className={classes.logo}>
          <img
            src={logo}
            alt="IQ logo"
            style={{ width: "50px", marginTop: "0.8rem" }}
          />
        </NavLink>
        <Avatar style={{ backgroundColor: "#3E24FB", marginTop: "18px" }}>
          <NotificationsIcon />
        </Avatar>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
        >
          {isOpen ? (
            <CloseIcon style={{ fontSize: "50px" }} />
          ) : (
            <MenuIcon style={{ fontSize: "50px" }} />
          )}
        </IconButton>
        <Grid item xs={1} />
      </Grid>
      <Grid className={classes.move}>
        <AppBar
          className={
            isOpen
              ? clsx(classes.gridMobile, { [classes.drawerOpen]: open })
              : clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })
          }
        >
          <Drawer
            variant="permanent"
            className={classes.move}
            classes={{
              paper:
                window.innerWidth <= 500
                  ? clsx({
                      [classes.drawerOpen]: open,
                    })
                  : clsx({
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    }),
            }}
          >
            <List>
              <Link to="/profile" className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <Avatar
                      alt="Remy Sharp"
                      src={Face}
                      className={classes.large}
                    />
                  </ListItemIcon>
                </ListItem>
              </Link>

              <Link
                className={classes.link}
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
              >
                <FormGroup>
                  <FormControlLabel
                    style={{ fontSize: "0.2rem" }}
                    control={
                      <Switch
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        classes={{
                          root: classes.root,
                          switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track,
                          checked: classes.checked,
                        }}
                        checked={checked}
                        onChange={handleChange}
                        name="checkedB"
                      />
                    }
                    label="Switch To Tutor"
                  />
                </FormGroup>
              </Link>
            </List>
            <List
              aria-label="open drawer"
              onMouseEnter={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
            >
              <List>
                <Link
                  to="/dashboard"
                  style={activeNav("/dashboard")}
                  className={classes.link}
                >
                  <ListItem button onClick={closeMobileMenu}>
                    <ListItemIcon>
                      <ViewQuiltOutlinedIcon style={activeNav("/dashboard")} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>Dashboard</Typography>
                    </ListItemText>
                  </ListItem>
                </Link>

                <Link
                  to="/courses"
                  style={activeNav("/courses")}
                  className={classes.link}
                >
                  <ListItem button onClick={closeMobileMenu}>
                    <ListItemIcon>
                      <ImportContactsIcon style={activeNav("/courses")} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>Courses</Typography>
                    </ListItemText>
                  </ListItem>
                </Link>

                <Link
                  to="/explore"
                  style={activeNav("/explore")}
                  className={classes.link}
                >
                  <ListItem button onClick={closeMobileMenu}>
                    <ListItemIcon>
                      <SearchOutlinedIcon style={activeNav("/explore")} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>Explore</Typography>
                    </ListItemText>
                  </ListItem>
                </Link>

                <Link
                  to="/classroom"
                  style={activeNav("/classroom")}
                  className={classes.link}
                >
                  <ListItem button onClick={closeMobileMenu}>
                    <ListItemIcon>
                      <SchoolIcon style={activeNav("/classroom")} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>Classroom</Typography>
                    </ListItemText>
                  </ListItem>
                </Link>

                <Link
                  to="/settings"
                  style={activeNav("/settings")}
                  className={classes.link}
                >
                  <ListItem button onClick={closeMobileMenu}>
                    <ListItemIcon>
                      <SettingsIcon style={activeNav("/settings")} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>Settings</Typography>
                    </ListItemText>
                  </ListItem>
                </Link>

                <Link
                  to="/help"
                  style={activeNav("/help")}
                  className={classes.link}
                >
                  <ListItem button onClick={closeMobileMenu}>
                    <ListItemIcon>
                      <LiveHelpIcon style={activeNav("/help")} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>Help</Typography>
                    </ListItemText>
                  </ListItem>
                </Link>
              </List>
              <Divider />
              <List style={{ backgroundColor: "#343a48" }}>
                <Link to="/" style={activeNav("/")} className={classes.link}>
                  <ListItem button style={{ margin: "0px" }}>
                    <img src={logo} alt="logo" style={{ width: "50px" }} />
                  </ListItem>
                </Link>
              </List>
            </List>
            <Divider />
            <Divider />
            <Button className={classes.button} style={{ padding: "10px" }}>
              <Link
                to="/login"
                className={classes.logout}
                onClick={() => {
                  signoutCookie(() => {});
                }}
              >
                Log out
              </Link>
            </Button>
            <Divider />
          </Drawer>
        </AppBar>
      </Grid>
      <Grid style={display} className={classes.verifyGrid}>
        <Paper elevation={0} className={classes.verification}>
          <Typography variant="h4" style={{ margin: "0.625rem" }}>
            Verification <SecurityIcon color="primary" />
          </Typography>
          <Typography
            variant="body1"
            style={{ paddingTop: "1.3rem", margin: "0.625rem" }}
          >
            Please Input your password to verify you have a tutor account
          </Typography>
          <input
            type="password"
            fullWidth={true}
            placeholder="Password"
            className={classes.input}
          />
          <Button className={classes.button}>Proceed</Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withRouter(DashboardNav);
