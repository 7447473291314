import React, { useState, useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DashboardNav from "../../Layout/DashboardNav";
import { DashBoardHeader } from "../../Layout/InputButton";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import DateRangeIcon from "@material-ui/icons/DateRange";
import clsx from "clsx";

// import SewingImg from "../../images/sewing.png";
import LinkIcon from "@material-ui/icons/Link";
import { Link } from "react-router-dom";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import NoteIcon from "@material-ui/icons/Note";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import WarningIcon from "@material-ui/icons/Warning";
import axios from "axios";

// import DynamicComponent from "../Components/Dashboard/DynamicComponent";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#FFF",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#32CD32",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
    borderLeft: "solid 2px #f2f2f2",
    borderRadius: 10,
  },
  wrapper: {
    width: "100%",
    marginRight: "5px",
    borderLeft: "solid 8px",
    borderLeftColor: "blue",
    borderRadius: 10,
    margin: 10,
    // height: 40,
  },
  accordion: {
    // height: 40,
    marginLeft: 6,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
      fontSize: theme.typography.pxToRem(10),
    },
  },
  checkCircle: {
    marginLeft: "8px",
    marginTop: "-6px",
    marginBottom: "-6px",
    color: "#00cc00",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  secondaryHeading: {
    flexBasis: "30%",
    marginRight: "5px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tetiaryHeading: {
    flexBasis: "30%",
    marginRight: "5px",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  spanner: {
    marginBottom: 6,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bg: {
    backgroundColor: "#f2f2f2",
  },
  content: {
    margin: "20px",
  },
  btnContent: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(5),
      fontWeight: "bolder",
    },
  },

  completedIcon: {
    fontSize: "18px",
    marginLeft: "8px",
    marginTop: "-6px",
    marginBottom: "-6px",
    color: "#00cc00",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      fontSize: theme.typography.pxToRem(10),
    },
  },

  playButton: {
    fontSize: "18px",
    marginRight: "8px",
    marginTop: "-6px",
    marginBottom: "-6px",
    color: "#808080",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      fontSize: theme.typography.pxToRem(10),
    },
  },
  button: {
    backgroundColor: "#FFF",
    color: "#000",
    width: "60%",
    padding: "8px",
    border: "none",
    borderRadius: "50px",
    margin: "10px",
    position: "relative",
    left: "240px",
    textDecoration: "none",
    opacity: "2",
    "&:hover": {
      backgroundColor: "#3E24FB",
      color: "#000",
    },
  },
  btnBack: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btnQuestion: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  courseProgess: {
    margin: 5,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function CoursesList() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [userId, setUserId] = useState([]);
  const [accessToken, setAccessToken] = useState([]);
  const [courseId, setCourseId] = useState();
  const [coursesModule, setCoursesModule] = useState([]);
  const [courseTitle, setCourseTitle] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (content, module) => {
    localStorage.setItem("moduleId", module.moduleId);
    localStorage.setItem("module", `Module ${module.id}: ${module.title}`);
    localStorage.setItem("moduleContent", JSON.stringify(module.contents));
    localStorage.setItem("contentId", content.id);
    localStorage.setItem(
      "contentTitle",
      `${content.title}(${content.duration})`
    );
  };

  useEffect(() => {
    async function getData() {
      if (window !== "undefined") {
        const uData = await localStorage.getItem("user");
        const sentCourseId = await localStorage.getItem("courseId");
        const sentCourseTitle = await localStorage.getItem("courseName");
        const userData = (await uData) ? JSON.parse(uData) : "";
        if (userData) {
          setUserId(userData.user._id);
          setAccessToken(userData.accessToken);
          setCourseId(sentCourseId);
          setCourseTitle(sentCourseTitle);
        }
      }
    }
    getData();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/v1/courses/${courseId}/users/${userId}/modules`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        // setLoading(false);
        console.log("Module DATA", res.data.data.modules);
        setCoursesModule(res.data.data.modules);
      })
      .catch((error) => {
        console.error("Module Error", error);
      });
  }, [accessToken, userId, courseId]);

  return (
    <>
      <Grid container xs={12} className={classes.bg}>
        <DashboardNav />
        <Grid item xs={10} container className={classes.content}>
          <Grid
            item
            container
            style={{
              width: "100%",
              padding: "20px",
            }}
          >
            <DashBoardHeader />
            <Grid
              item
              xs={10}
              container
              style={{ marginTop: "20px", width: "100%" }}
            >
              <Grid item xs={5} className={classes.courseProgess}>
                <Typography style={{ fontSize: 12, fontWeight: "800" }}>
                  {courseTitle}
                </Typography>
                <Grid
                  item
                  style={{ margin: 10, width: "22em", marginLeft: -1 }}
                >
                  <BorderLinearProgress variant="determinate" value={50} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  className={clsx(classes.btnBack, classes.button)}
                  style={{
                    padding: "8px",
                    backgroundColor: "#FFF",
                    opacity: "2",
                    color: "#000",
                  }}
                  component={Link}
                  to="/courses"
                  onClick={() => {
                    localStorage.removeItem("courseId");
                    localStorage.removeItem("courseTitle");
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "800",
                      textDecoration: "none",
                    }}
                  >
                    Go Back
                  </Typography>
                </Button>
                <Button
                  className={clsx(classes.btnQuestion, classes.button)}
                  startIcon={<QuestionAnswerIcon />}
                  component={Link}
                  to="/classroom"
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "800",
                      textDecoration: "none",
                    }}
                  >
                    Ask Questions
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {coursesModule.map((module) => {
              return (
                <Grid
                  item
                  xs={12}
                  container
                  className={classes.root}
                  key={module.id}
                >
                  <div className={classes.wrapper}>
                    <Accordion
                      className={classes.accordion}
                      expanded={expanded === `panel${module.id}`}
                      onChange={handleChange(`panel${module.id}`)}
                    >
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          Module {module.id}: {module.title}
                          <CheckCircleIcon className={classes.checkCircle} />
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                          <DateRangeIcon
                            style={{ fontSize: "medium", marginBotom: "-2px" }}
                          />
                          Date: November 5
                        </Typography>
                        <Typography className={classes.tetiaryHeading}>
                          <LinkIcon
                            style={{ fontSize: "24px", marginBottom: "-5px" }}
                          />
                          Resources
                        </Typography>
                        <Button>
                          <BookmarkBorderIcon
                            style={{
                              fontSize: "20px",
                              marginBottom: "-5px",
                              marginLeft: "3em",
                            }}
                          />
                        </Button>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item md={4} className={classes.img}>
                          <img
                            src={module.image}
                            alt="Database img"
                            style={{
                              width: "200px",
                              borderRadius: "10px",
                              height: "200px",
                            }}
                          />
                        </Grid>
                        <Grid
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {module.contents.map((content) => (
                            <Grid
                              item
                              className={classes.column}
                              key={content.id}
                            >
                              <Grid className={classes.flex}>
                                <Grid className={classes.spanner}>
                                  <PlayCircleFilledIcon
                                    className={classes.playButton}
                                  />
                                  <Button
                                    style={{ textDecoration: "none" }}
                                    className={classes.btnContent}
                                    component={Link}
                                    to="/viewModule"
                                    onClick={() => handleClick(content, module)}
                                  >
                                    {content.title}({content.duration})
                                  </Button>
                                  <DoneAllIcon
                                    className={classes.completedIcon}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CoursesList;
