import React, { useState } from "react";
import {
  Grid, Button, Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DashboardNav from "../Layout/DashboardNav";

import { DashBoardHeader } from "../Layout/InputButton";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CommentIcon from '@material-ui/icons/Comment';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DynamicComponent from "../Components/Dashboard/DynamicComponent"


import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: "20px",
    width: "125%",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  bg: {
    backgroundColor: "#f2f2f2",
  },
  content: {
    margin: "0 0 0 0.8rem",
  },
  Buttonegde: {
    borderRadius: "1rem",
    padding: "0.7rem 2rem",
    backgroundColor: "#fff",
    color: "#3e23ff",
    "&:hover": {
      backgroundColor: "#3e23ff",
      color: "#fff",
    }
  },
  Buttonegde2: {
    borderRadius: "1.25rem",
  },
  body2: {
    fontSize: "0.75rem",
    fontWeight: "900",
    // backgroundColor: "#3e23ff",
    // color: "#fff"
  }
}));


function Classroom() {
  const classes = useStyles();
  const [user, changeUser] = useState("assignment");

  return (
    <Grid container xs={12} className={classes.bg}>
      <DashboardNav />
      {/*/#.....*/}
      <Grid item xs={10} container className={classes.content}>
        <Grid
          item
          container
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <DashBoardHeader />
          {/* <Grid item className={classes.root} sm={10}>
            <ButtonGroup size="small" aria-label="small outlined button group" fullWidth="false" className={classes.Buttonegde2}>
              <Button className={classes.Buttonegde} startIcon={<CommentIcon />} variant="contained" onClick={() => changeUser("assignment")}><Typography inherit="left" className={classes.body2}>Assessment</Typography></Button>
              <Button className={classes.Buttonegde} variant="contained" startIcon={<NewReleasesIcon />} onClick={() => changeUser("certificate")}><Typography className={classes.body2}>Certificate</Typography></Button>
              <Button className={classes.Buttonegde} startIcon={<QuestionAnswerIcon />} variant="contained" onClick={() => changeUser("QNdA")}><Typography className={classes.body2}>Forum</Typography></Button>
            </ButtonGroup>
          </Grid> */}
          <Grid item sm={10} className={classes.root}>
            <ButtonGroup size="small" aria-label="small outlined button group" fullWidth="false" className={classes.Buttonegde2}>
              <Button className={classes.Buttonegde} startIcon={<CommentIcon />} variant="contained" onClick={() => changeUser("assignment")}><Typography inherit="left" className={classes.body2}>Assessment</Typography></Button>
              <Button className={classes.Buttonegde} variant="contained" startIcon={<NewReleasesIcon />} onClick={() => changeUser("certificate")}><Typography className={classes.body2}>Certificate</Typography></Button>
              <Button className={classes.Buttonegde} startIcon={<QuestionAnswerIcon />} variant="contained" onClick={() => changeUser("QNdA")}><Typography className={classes.body2}>Forum</Typography></Button>
            </ButtonGroup>
          </Grid>
          <DynamicComponent user={user} />
        </Grid>
      </Grid>
      {/*...... */}
      <Grid item xs={1} />
    </Grid>
  );
}

export default Classroom;