import React from "react";
import ivy from "../images/image.png";
import bg from "../images/BG.png";
import screen from "../images/screenIcon.png";
import cert from "../images/certIcon.png";
import paper from "../images/paperIcon.png";
import mailer from "../images/minutemailer.png";
import dots from "../images/dots_1.png";
import phone from "../images/phone.png";
import SendIcon from "@material-ui/icons/Send";
import Footer from "../Layout/Footer";
// import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  GridList,
  GridListTile,
} from "@material-ui/core";
import NavBar from "../Layout/NavBar";
import Carousel from "../Layout/Carousel";
import { SearchInput } from "../Layout/InputButton";

import {
  CardComponent,
  CardComponent2,
  CardHome,
  CourseCard,
} from "../Layout/Card";

const useStyles = makeStyles((theme) => ({
  bg: {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ivy})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    margin: "0px",
    color: "#fff",
    fontFamily: `Poppins, sans-serif`,
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "center",
    },
  },
  landing: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "50rem",
    // [theme.breakpoints.down("sm")]: {
    //   margin: "0 auto",
    // },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
  },
  input: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3.125rem",
    padding: "1.25rem",
    outline: "none",
    marginTop: "0.625rem",
    width: "30%",
    position: "relative",
  },

  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "15%",
    padding: "1rem",
    border: "none",
    borderRadius: "3.125rem",
    margin: "0.625rem",
    position: "relative",
    left: "-3.125rem",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
  },
  //   section 2
  section2Bg: {
    // height: "100vh",
    // width: "100vw",
    backgroundColor: "#f9ffff",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "115%",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  heading: {
    margin: "0px auto",
    maxWidth: "37.5rem",
    textAlign: "center",
    paddingTop: "2.5rem",
    height: "12.5rem",
  },
  cardContainter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.625rem",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
    },
  },
  card: {
    maxWidth: "16rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1.25rem",
    height: "100%",
    borderRadius: "1.25rem",
    textAlign: "center",
    boxShadow: "5px 10px 8px #888888",
  },

  flexDetails: {
    width: "100%",
    marginTop: "4.4rem",
    display: "flex",
    height: "25%",
    color: "#fff",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      // maxWidth: "100%",
    },
  },

  studentCount: {
    display: "flex",
    margin: "0px",
    justifyContent: "space-evenly",
    padding: "0px",
  },

  studentCountInfo: {
    margin: "0px",
    display: "flex",
    justifyContent: "space-evenly",
    paddingBottom: "1rem",
  },

  //section3
  section3: {
    // height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      // maxWidth: "100%",
    },
  },
  exploreButton: {
    backgroundColor: "#3e23ff",
    border: "none",
    color: "#fff",
    width: "50%",
    padding: "1rem",
    borderRadius: "3.16rem",
    margin: "4.36rem auto",
    textAlign: "center",
    display: "block",
    fontFamily: `Poppins, sans-serif`,
    fontSize: "1.25rem",
    "&:hover": {
      backgroundColor: "#3e5bee",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  gettingStartedBtn: {
    display: "block",
    margin: "10px 100px",
    backgroundColor: "#3e23ff",
    borderRadius: "50px",
    padding: "15px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3e5bee",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "1rem auto",
      width: "50%",
    },
  },

  articlesSection: {
    display: "flex",
    justifyContent: "space-around",
    margin: "30px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      // width: "50%",
    },
  },

  articlesSectionBtn: {
    padding: "15px",
    borderRadius: "50px",
    width: "200px",
    backgroundColor: "#fff",
    border: "1px solid #3e23ff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  //section4

  dots: {
    float: "left",
    marginTop: "20%",
    marginLeft: "-18px",
    margin: "3.125rem",
    height: "38%",
  },

  GridAction: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },

  notification: {
    // height: "50vh",
    maxWidth: "100%",
    backgroundColor: "#343a48",
    color: "#fff",
    margin: "0 auto",
    paddingTop: "70px",
  },

  notificationDots: {
    width: "80px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  //section5
  phoneSection: {
    height: "30rem",
    backgroundColor: "#3e23ff",
    color: "#fff",
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  phone: {
    height: "50rem",
    display: "block",
    position: "relative",
    top: "-13rem",
    left: "-7rem",
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div>
      {/*Section 1*/}
      <NavBar />
      <Grid container className={classes.bg}>
        <Grid item xs={1} />
        <Grid item xs={9} className={classes.landing}>
          <Typography variant="h1" className={classes.title}>
            Learn and Teach
          </Typography>
          <Typography variant="h2" className={classes.title}>
            Find Great Online Courses
          </Typography>
          <Typography varient="h5">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature literature,
            discovered the undoubtable source.
          </Typography>
          <SearchInput
            name="Search"
            placeholder="Find Any Course"
            // icon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={2} />
      </Grid>

      {/*Section 2*/}
      <Grid container className={classes.section2Bg}>
        <Grid item className={classes.heading}>
          <Typography variant="h4">
            Get course made by Great Tutors, selected and screened
          </Typography>
          <p>
            We give you the value for your money, great tutors that will teach
            you what you need to master your craft
          </p>
        </Grid>
        <Grid item container className={classes.cardContainter}>
          <CourseCard
            img={<img src={screen} alt="icon" style={{ width: "70px" }} />}
            heading="User friendly LMS"
            description="It has roots in a piece of classical Latin literature literature, discovered the undoubtable source."
          />
          <Card
            className={classes.card}
            style={{
              marginTop: "50px",
              backgroundColor: "#343a48",
              color: "#fff",
            }}
          >
            <img src={cert} alt="icon" style={{ width: "70px" }} />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h4">
                Certification
              </Typography>
              <Typography variant="body2" color="white" component="p">
                It has roots in a piece of classical Latin literature
                literature, discovered the undoubtable source.
              </Typography>
            </CardContent>
          </Card>
          <CourseCard
            img={<img src={paper} alt="icon" style={{ width: "70px" }} />}
            heading="Approved Courses"
            description="It has roots in a piece of classical Latin literature literature, discovered the undoubtable source."
          />
        </Grid>
        <Grid item className={classes.flexDetails}>
          <Grid
            xs={12}
            md={6}
            style={{
              // width: "50%",
              backgroundColor: "#343a48",
              color: "#fff",
              padding: "15px",
            }}
          >
            <Typography
              variant="h5"
              style={{
                width: "70%",
                margin: "0 auto",
                textAlign: "left",
                paddingBottom: "7px",
              }}
            >
              Join Thousand of tutor to earn while sharing great knowledge{" "}
            </Typography>
            <Button className={classes.gettingStartedBtn}>Get started</Button>
          </Grid>
          <Grid
            xs={12}
            md={6}
            style={{
              // width: "50%",
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ivy}) center`,
              paddingTop: "50px",
            }}
          >
            <div className={classes.studentCount}>
              <Typography variant="h1">10k</Typography>
              <Typography variant="h1">50+</Typography>
            </div>
            <div className={classes.studentCountInfo}>
              <Typography variant="h4">Students</Typography>
              <Typography variant="h4">Categories</Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/*Section 3*/}
      <div className={classes.section3}>
        <div className={classes.heading} style={{ height: "100px" }}>
          <Typography variant="h3">Featured Courses</Typography>
        </div>
        <Grid item xs={12} container justify="center">
          <Grid item xs={0} sm={1} />
          <Grid
            item
            container
            xs={11}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              overflow: "hidden",
            }}
          >
            <GridList
              cellHeight={{ xs: 300, md: 500 }}
              cols={{ xs: 1, md: 3.5 }}
              className={classes.GridAction}
            >
              <GridListTile>
                <CardHome />
              </GridListTile>
              <GridListTile>
                <CardHome />
              </GridListTile>
              <GridListTile>
                <CardHome />
              </GridListTile>
              <GridListTile>
                <CardHome />
              </GridListTile>
              <GridListTile>
                <CardHome />
              </GridListTile>
              <GridListTile>
                <CardHome />
              </GridListTile>
            </GridList>
          </Grid>
        </Grid>
        <div>
          <button className={classes.exploreButton}>Explore all Courses</button>
        </div>
      </div>

      {/*Section 4 */}
      <div
        style={{
          // height: "100vh",
          width: "100vw",
        }}
      >
        <Grid className={classes.notification} container>
          <Grid item xs={0} sm={2}>
            <img src={dots} alt="dots" className={classes.notificationDots} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <img
              src={mailer}
              alt="mailer Icon"
              style={{ width: "50px", height: "50px" }}
            />
            <Typography
              variant="h4"
              style={{ width: "450px", padding: "10px" }}
            >
              Receive Latest Update and Notifications
            </Typography>
            <Typography variant="body1">
              It has roots in a piece of classical Latin literature literature,
              discovered the undoubtable source.
            </Typography>
            <SearchInput
              name="Send"
              placeholder="Enter Email"
              endIcon={<SendIcon />}
            />
          </Grid>
          <Grid item xs={false} sm={2} />
        </Grid>
        <div>
          <div className={classes.articlesSection}>
            <Typography variant="h4">Articles and Journals</Typography>
            <button className={classes.articlesSectionBtn}>View More</button>
          </div>
          {/* <Grid
            container
            xs={12}
            justify="center"
            spacing={3}
          >
            <Grid item>
              <CardComponent2 />
            </Grid>
            <Grid item>
              <CardComponent2 />
            </Grid>
            <Grid item>
              <CardComponent2 />
            </Grid>
            <Grid item>
              <CardComponent2 />
            </Grid>
          </Grid> */}
          <Grid item xs={12} container>
            <Grid item xs={0} sm={1} />
            <Grid
              item
              container
              xs={11}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                overflow: "hidden",
              }}
            >
              <GridList
                cellHeight={500}
                cols={{ xs: 1, sm: 3.5 }}
                style={{
                  flexWrap: "nowrap",
                  transform: "translateZ(0)",
                }}
              >
                <GridListTile>
                  <CardComponent2 />
                </GridListTile>
                <GridListTile>
                  <CardComponent2 />
                </GridListTile>
                <GridListTile>
                  <CardComponent2 />
                </GridListTile>
                <GridListTile>
                  <CardComponent2 />
                </GridListTile>
                <GridListTile>
                  <CardComponent2 />
                </GridListTile>
                <GridListTile>
                  <CardComponent2 />
                </GridListTile>
              </GridList>
            </Grid>
          </Grid>
        </div>
      </div>

      {/*Section 5 */}
      <Grid container xs={12}>
        <Carousel />
        <Grid item xs={12} md={6} className={classes.phoneSection}>
          <img src={phone} alt="phone" className={classes.phone} />
        </Grid>
      </Grid>
      {/*Section 6 */}
      <Footer />
    </div>
  );
};

export default Home;
