import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import EmailIcon from '@material-ui/icons/Email';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import SewingImg from "../../images/sewing.png";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "15px",
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    sectionMargin: {
        marginTop: "40px"
    },
    section2Bg: {
        height: "55vh",
        backgroundColor: "#f9ffff",
        borderRadius: "20px",
    },
    sectionText: {
        // height: "50vh",
        marginTop: "50px",
        marginBottom: "0",
        marginLeft: "20px",
        marginRight: "0"

    },
    profileInput: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: "40px",
    },
    img: {
        width: "15rem",
        height: "11rem",
        borderRadius: "10px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    settingsHeader: {
        margin: "15px"
    },
    downloadbutton: {
        padding: "10px 50px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        color: "grey",
        "&:hover": {
            backgroundColor: "#fff",
            color: "black",
        },
    },
    emailButton: {
        padding: "10px 50px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        color: "grey",
        "&:hover": {
            backgroundColor: "#fff",
            color: "black",
        },
    },
    RsetPButton: {
        padding: "10px 30px",
        textAlign: "center",
        backgroundColor: "#f2f2f2",
        color: "grey",
        "&:hover": {
            backgroundColor: "#fff",
            color: "black",
        },
    },
    saveButton: {
        padding: "10px 50px",
        textAlign: "center",
        backgroundColor: "#3e23ff",
        color: "#fff",
        borderRadius: "20px",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#3e23ff",
        },
    },
}))


function Billing() {
    const classes = useStyles();
    return (
        <Grid container sm={12} className={classes.sectionMargin}>
            <Grid container item sm={12}>
                <Grid item sm={12} className={classes.section2Bg} >
                    <Grid className={classes.sectionText}>
                        <Grid sm={12} className={classes.settingsHeader}>
                            Billing settings
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Billing
