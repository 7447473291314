import {
  AppBar,
  Grid,
  MenuItem,
  MenuList,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../images/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  nav: {
    color: "#fff",
    boxShadow: "none",
    outline: "none",
  },
  navContainer: {},
  navMobile: {
    position: "relative",
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      flex: "1",
    },
  },

  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      display: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
      width: "100px",
    },
  },

  gridMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "5rem",
    left: "0rem",
    width: "100vw",
    height: "70vh",
    color: "#fff",
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`,
    transition: "all 0.5s ease",
    paddingTop: "2rem",
  },

  menuButtonMobile2: {
    display: "grid",
    // flexDirection: "column",
    // // width: "100%",
    // height: "90vh",
    // position: "absolute",
    // // top: "10px",
    // // left: "-100px",
    // opacity: "1",
    // transition: "all 0.5s ease",
  },
  menuList: {
    display: "flex",
    flex: "1",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      flex: "none",
      width: "100%",
      alignItems: "center",
    },
  },
  menuList2: {
    display: "flex",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "90%",
      alignItems: "center",
      fontSize: "4rem",
    },
  },
  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    padding: "15px",
    borderRadius: "50px",
    margin: "10px",
    "&:hover": {
      backgroundColor: "#3e10ff",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      // display: "block",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const closeMobileMenu = () => setIsOpen(false);

  return (
    <AppBar position="absolute" color="transparent" className={classes.nav}>
      <Grid container xs={12}>
        <Grid item xs={0} sm={1} />
        <Grid item container xs={12} sm={10} style={{ display: "flex" }}>
          <NavLink to="/" className={classes.logo}>
            <img src={logo} alt="IQ logo" style={{ width: "100px" }} />
          </NavLink>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            {isOpen ? (
              <CloseIcon style={{ fontSize: "50px" }} />
            ) : (
              <MenuIcon style={{ fontSize: "50px" }} />
            )}
          </IconButton>
          <Grid className={isOpen ? classes.gridMobile : classes.gridContainer}>
            <MenuList
              // className={isOpen ? classes.menuButtonMobile : classes.menuList}
              className={classes.menuList}
            >
              <MenuItem component={Link} to="/" onClick={closeMobileMenu}>
                Courses
              </MenuItem>
              <MenuItem component={Link} to="/signup" onClick={closeMobileMenu}>
                About Us
              </MenuItem>
              <MenuItem component={Link} to="/login" onClick={closeMobileMenu}>
                Articles
              </MenuItem>
              <MenuItem component={Link} to="/login" onClick={closeMobileMenu}>
                Contact
              </MenuItem>
            </MenuList>
            <MenuList
              // className={isOpen ? classes.menuButtonMobile2 : classes.menuList2}
              className={classes.menuList2}
            >
              <MenuItem component={Link} to="/login" onClick={closeMobileMenu}>
                Log in
              </MenuItem>
              <MenuItem
                component={Link}
                to="/signup"
                className={classes.button}
                onClick={closeMobileMenu}
              >
                Get Started
              </MenuItem>
            </MenuList>
          </Grid>

          {/* <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            {isOpen ? (
              <CloseIcon style={{ fontSize: "50px" }} />
            ) : (
              <MenuIcon style={{ fontSize: "50px" }} />
            )}
          </IconButton> */}
          {/* </Grid> */}
        </Grid>
        <Grid item xs={0} sm={1} />
      </Grid>
    </AppBar>
  );
};

export default NavBar;
