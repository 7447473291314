import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import AssessmentState from "./State_management/Assessment/AssessState"
import InprogressState from './State_management/Assessment/Inprogress';



const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: "20px",
    },
    note: {
        color: "red",
        fontWeight: "bold",
        width: "100%"
    },
}))

function Assessment() {

    const classes = useStyles();
    return (
        <Grid>
            <Grid container className={classes.button} sm={12}>

                <Grid item sm={12}>
                    <Typography variant="body2">
                        <span className={classes.note}>Note</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a aliquam elit. Ut eget
                        justo sed diam bibendum accumsan in quis odio. Praesent sed congue ipsum. Cras vehicula mi
                        ut justo aliquam tempus. Sed hendrerit fermentum est. Cras tincidunt risus varius neque egestas
                        volutpat
                    </Typography>
                </Grid>
                <AssessmentState />
                {/* <InprogressState /> */}
                {/* <AssessmentState />
                
                <InprogressState /> */}
            </Grid>
        </Grid>
    )
}

export default Assessment