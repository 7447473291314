import React, { useState } from "react";
import ivy from "../images/image.png";
import NavBar from "../Layout/NavBar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect, useHistory } from "react-router-dom";
import swal from "sweetalert"

//import 'react-toastify/dist/ReactToastify.min.css';
import { authenticate, isAuth } from "../Components/Auth/Storage";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import dots from "../images/dots_1.png";

const useStyles = makeStyles((theme) => ({
  bg: {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ivy})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    margin: "0px",
    // paddingTop: "70px",
    color: "#fff",
    fontFamily: `Poppins, sans-serif`,
  },

  dots: {
    float: "left",
    marginTop: "20%",
    marginLeft: "-1.125rem",
    margin: "3.125rem",
    height: "38%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  dot: {
    float: "right",
    marginRight: "-0.75rem",
    margin: "3.125rem",
    marginTop: "20%",
    height: "38%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  form: {
    backgroundColor: "#343a48",
    marginTop: "5rem",
    paddingTop: "10%",
    borderRadius: "0.625rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6.25rem",
      height: "70vh",
    },
  },
  input: {
    width: "90%",
    borderRadius: "3.125rem",
    outline: "none",
    backgroundColor: "#fff",
    border: "none",
    padding: "1.25rem",
    margin: "0.625rem",
  },
  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "90%",
    padding: "1.25rem",
    outline: "none",
    borderRadius: "3.125rem",
    margin: "0.625rem",
    "&:hover": {
      backgroundColor: "#3e10ff",
      color: "#fff",
    },
  },
  buttonTop: {
    border: "2px solid #fff",
    borderRadius: "3.125rem",
    padding: "1rem",
    margin: "1.25rem",
    color: "#fff",
  },
  toggleIconImg: {
    position: "absolute",
  },
}));

// class Login extends Component {
//   state = {
//     isPasswordShown : false
//   }

//   toggleButtonVisibility = () => {
//     const { isPasswordShown } = this.state
//     this.setState({ isPasswordShown: !isPasswordShown })
//   }

// }

const Login = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
    buttonText: "Login",
  });

  const { email, password, buttonText } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const clickSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, buttonText: "Submitting..." });

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/v1/auths/users/login`,
      data: { email, password },
    })
      .then((res) => {
        console.log("SIGNIN SUCESSFULLY MADE", res.data.message);
        //TODO..save the response(user data, Token) to (local storage, cookie)
        authenticate(res, () => {
          setValues({
            ...values,
            email: "",
            password: "",
            buttonText: "Submitted",
          });
          console.log(res);
          // swal({
          //   text: res.data.message,
          //   icon: "success",
          //   button: false
          // });
          // history.push("/dashboard");
        });
      })
      .catch((err) => {
        if (err.response) {
          swal({
            text: err.response.data.message,
            icon: "error",
            button: "ok",
          });
          setValues({
            ...values,
            buttonText: "Try Again",
          })
        } else if (err.request) {
          swal({
            text: "Connection Error",
            icon: "info",
            button: "Try Again"
          });
          setValues({
            ...values,
            buttonText: "Try Again",
          });
        } else {
          swal({
            text: "Something went wrong",
            icon: "info",
            button: false
          });
          setValues({
            ...values,
            buttonText: "Try Again",
          });
        }

        // console.log("SIGNIN ERROR", err.response.data.message);
        // setValues({ ...values, buttonText: "Try again" });
        // toast.error(err.response.data.message);
        // throw new error("Check your network connection")
        // !err.response.data.message ? toast.error("err.response.data.message") : toast.error(err.response.data.message)
      });
  };

  const loginRedirect = () => {
    history.push("/signup");
  };

  const classes = useStyles();
  return (
    <Grid container className={classes.bg} xs={12}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NavBar />
      {isAuth() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
      <Grid item xs={0} md={3} />
      <Grid item xs={12} md={6} className={classes.form}>
        <img className={classes.dots} src={dots} alt="dots" />
        <form className={classes.root} noValidate autoComplete="off">
          <Typography variant="h3">Login</Typography>
          <Button className={classes.buttonTop} onClick={loginRedirect}>
            Don't Have an account? REGISTER
          </Button>

          <input
            variant="outlined"
            type="email"
            fullWidth={true}
            placeholder="Email"
            className={classes.input}
            onChange={handleChange("email")}
            value={email}
          />
          <input
            type="password"
            fullWidth={true}
            placeholder="Password"
            className={classes.input}
            onChange={handleChange("password")}
            value={password}
          />
          <Button onClick={clickSubmit} className={classes.button}>
            {buttonText}
          </Button>
        </form>
        <img className={classes.dot} src={dots} alt="dots" />
      </Grid>
      <Grid item xs={0} md={3} />
    </Grid>
  );
};

export default Login;
