import React from 'react'
import profile from "./Profile"
import billing from "./Billing"


const components = {
    profile: profile,
    billing: billing,
}

function DynamicComponent(props) {

    const SelectUser = components[props.user]
    return <SelectUser />
}

export default DynamicComponent