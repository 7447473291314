import React from "react";
import { Grid, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Link } from "react-router-dom";
import { signoutCookie } from "../Components/Auth/Storage";
// import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  logout: {
    color: "#fff",
    textDecoration: "none",
    marginRight: "1rem",
  },
  input: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3.2rem",
    padding: "1.2rem",
    outline: "none",
    marginTop: "0.7rem",
    width: "40%",
    position: "relative",
    boxShadow: "6px 7px 16px -1px rgba(0,0,0,0.53)",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      fontSize: "0.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "65%",
      fontSize: "0.6rem",
      padding: "1rem",
    },
  },

  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "18%",
    padding: "1.4rem",
    border: "none",
    borderRadius: "3.2rem",
    margin: "0.625rem",
    position: "relative",
    left: "-5.625rem",
    top: "-0.188rem",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "26%",
      padding: "1.2rem",
      fontSize: "0.6rem",
      top: "0",
    },
    [theme.breakpoints.down("xs")]: {
      width: "25%",
      padding: "1rem",
      left: "-3.625rem",
    },
  },

  dashboardBtn: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "18%",
    padding: "1.4rem",
    border: "none",
    borderRadius: "3.2rem",
    margin: "0.625rem",
    position: "relative",
    left: "-5.625rem",
    top: "-0.188rem",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "26%",
      padding: "1.2rem",
      fontSize: "0.6rem",
      top: "7px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "25%",
      padding: "1rem",
      left: "-3.625rem",
    },
  },
  searchBar: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  notification: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  inputMobile: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export const DashBoardHeader = ({ history }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} md={9} className={classes.inputMobile}>
        <input
          type="text"
          className={classes.input}
          placeholder="Find Any Course"
        />
        <Button className={classes.dashboardBtn}>Search</Button>
      </Grid>

      <Grid item xs={3} className={classes.notification}>
        <Avatar style={{ backgroundColor: "#3E24FB", marginRight: "3rem" }}>
          <NotificationsIcon />
        </Avatar>
        <Button
          className={classes.button}
          style={{ width: "50%", padding: "10px" }}
        >
          <Link
            to="/login"
            className={classes.logout}
            onClick={() => {
              signoutCookie(() => {});
            }}
          >
            Log out
          </Link>
        </Button>
      </Grid>
    </>
  );
};

export const SearchInput = (props) => {
  const classes = useStyles();
  return (
    <div>
      <input
        type="text"
        className={classes.input}
        placeholder={props.placeholder}
      />
      <Button className={classes.button} endIcon={props.endIcon}>
        {props.name}
        {/* {props.icon} */}
      </Button>
      {/* <Button className={(classes.button, classes.searchBar)}>
        {props.icon}
      </Button> */}
    </div>
  );
};
