import React, { useState, useEffect } from 'react'
import { Grid, Button, Typography, List, ListItem, ListItemIcon, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Modal from "react-modal"
import ModalForm from "./State_management/QndA/ModalAskQuestion"
import CloseIcon from '@material-ui/icons/Close';
import ModalAnswerQuestionForm from '../Dashboard/State_management/QndA/ModalAnswerQuestion';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Loader from 'react-loader-spinner'
import axios from "axios"
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import SendIcon from '@material-ui/icons/Send';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import { Link } from "react-router-dom";
import Face from "../../images/face.png";
import Moment from 'react-moment';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../theme";

import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import FavoriteIcon from '@material-ui/icons/Favorite';

import QndAState from "../Dashboard/State_management/QndA/QndAState"

// import Assignment from "../Components/Dashboard/Assignment"
// import Certificate from "../Components/Dashboard/Certificate"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "20px",
        '& > *': {
            margin: theme.spacing(1),
        },

    },
    pageRoot: {
        height: 60,
        // borderRadius: 5,
    },
    root2: {
        width: "100%",
        border: "none",
        boxShadow: "none"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        display: "flex",
        flexDirection: "column"
    },
    bg: {
        backgroundColor: "#f2f2f2",
    },
    content: {
        margin: "20px",
    },
    // Buttonegde: {
    //     borderRadius: "10px"
    // },
    // Buttonegde2: {
    //     borderRadius: "20px",
    // },
    Qbody: {
        marginTop: "30px",
    },
    // text: {
    //     padding: "2px 20px"
    // },
    // text2: {
    //     padding: "2px 2px"
    // },
    // textColor: {
    //     color: "black",
    //     marginLeft: "5px"
    // },
    large: {
        border: "3px solid #3e23ff",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        backgroundColor: "white",
        '&:hover': {
            color: "#3e23ff",
        },

    },
    colorBackground: {
        backgroundColor: "#fff",
        borderRadius: "20px",
        width: "320px",
    },
    textMargin: {
        margin: " 20px 20px",

    },
    space: {
        marginTop: "10px",
    },

    likeSize: {
        width: "10px",
        borderRadius: "50px",
    },
    white: {
        color: "white",
        backgroundColor: "#3e23ff",
        borderRadius: "50px",
        '&:hover': {
            color: "black",
            backgroundColor: "#3e23ff",
            borderRadius: "20px"
        },
    },
    loadingColor: {
        margin: "20px 0",
    },
    loading: {
        margin: "0 4px",
        color: "black"
    },
    closeButton: {
        color: "grey",
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "0.5rem 0.5rem 0.5rem 0.1rem",
        borderRadius: "70px",
        // margin: "10px",
        "&:hover": {
            backgroundColor: "#eb3a34",
            color: "#fff",
        }
    },
    answerFont: {
        fontSize: "12px"
    },
    black: {
        color: "black"
    },
    answerButtonDown: {
        padding: "5px 20px",
        borderRadius: "20px",
        backgroundColor: "grey",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#eb3a34",
            color: "#fff",
        }
    },
    age: {
        fontWeight: "bold"
    },
    Buttonegde: {
        borderRadius: "10px"
    },
    Buttonegde2: {

        borderRadius: "20px",
        backgroundColor: "#fff",
        color: "#3e23ff",
        "&:hover": {
            backgroundColor: "#3e23ff",
            color: "#fff",
        }
    },
    Buttonegde4: {
        marginLeft: "70px",
        // padding: "0 20px",
        borderRadius: "20px",
        backgroundColor: "#fff",
        color: "#3e23ff",
        "&:hover": {
            backgroundColor: "#3e23ff",
            color: "#fff",
        }
    },
    Buttonegde3: {
        // marginTop: "3px",
        borderRadius: "20px",
        backgroundColor: "#3e23ff",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#3e23ff",
        }
    },
    button: {
        marginTop: "30px",
    },
    text: {

        padding: "2px 20px",
        "&:hover": {

            color: "#fff",
        }
    },
    text2: {
        // backgroundColor: "#3e23ff",
        // color: "#fff",
        padding: "5px 10px",
        "&:hover": {
            // backgroundColor: "#fff",
            // color: "#3e23ff",
        }
    },
    textColor: {
        color: "black",
        marginLeft: "5px"
    },
    closeButton: {
        backgroundColor: "#fff",
        color: "grey",
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "0.5rem 0.5rem 0.5rem 0.1rem",
        // padding: "1rem 5rem",
        borderRadius: "50px",
        margin: "10px",
        "&:hover": {
            backgroundColor: "#eb3a34",
            color: "#fff",
        }
    },
    inputSearch: {
        whiteSpace: "pre",
        width: "100%",
        minWidth: "20%",
        padding: "10px 10px",
        borderRadius: "20px",
        borderTop: "0",
        borderRadius: "20px",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "2px solid #fff",
        fontSize: "0.9rem",
        fontWeight: "400",
        outline: "none",
        backgroundColor: "#fff",
        marginBottom: "10px",
        color: "#3e23ff",
        "&:hover": {
            borderBottom: "2px solid #3e23ff",
        }
    },
    buttonA: {
        backgroundColor: "#3e23ff",
        color: "#fff",
        padding: "1rem 3rem",
        borderRadius: "50px",
        margin: "10px",
        "&:hover": {
            backgroundColor: "#3e10ff",
            color: "#fff",
        }
    },
    textarea: {
        margin: "200px"
    },
    cardBackground: {
        backgroundColor: "#FFF",
        "&:hover": {
            backgroundColor: "#FFF"
        }
    }
}));

const Form = {
    // textAlign: 'center',
    // fontSize: "0.2rem",
    // fontWeight: "bold",
}
const Input = {
    width: "57vw",
    padding: "0.6rem",
    marginTop: "2rem",
    // borderTop: "0",
    // // borderRight: "0",
    // // borderLeft: "0",
    fontSize: "1rem",
    fontWeight: "300",
    outline: "none",
    borderRadius: "10px",
    borderBottom: "6px solid #3e23ff",
    // border: "100px 20px 30px 100px",
    // backgroundColor: "red"
}

const Input2 = {
    width: "57vw",
    height: "150px",
    padding: "0.6rem",
    margin: "0.5rem",
    borderTop: "0",
    borderRadius: "20px",
    borderLeft: "0",
    borderRight: "0",
    borderBottom: "6px solid #3e23ff",
    fontSize: "1rem",
    fontWeight: "300",
    outline: "none",
}



Modal.setAppElement("#root")
function QNdA() {
    const [open, setOpen] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [questionState, setQuestionState] = useState([])
    const [localData, setLocalData] = useState([])
    const [localToken, setLocalToken] = useState([])
    const [courseID, setCourseID] = useState([])
    const [loading, setLoading] = useState(true)
    const [like, setLike] = useState(false)
    const [likeReload, setLikesReload] = useState("")
    const [answer, setAnswer] = useState(false)
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [QuestionsPerPage, setQuestionsPerPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(0)
    const [reset, setReset] = useState("")
    const [values, setValues] = useState({
        title: "",
        description: "",
        buttonText: "Send",
    });

    const {
        title,
        description,
        buttonText,
    } = values;

    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const classes = useStyles();

    const handleSearch = (e) => {
        e.preventDefault()
        setSearchInput(e.target.value)
    }

    useEffect(() => {
        function fetchLocalData() {
            if (window !== "undefined") {
                const uData = localStorage.getItem('user');
                const userData = uData ? JSON.parse(uData) : '';
                // console.log("FROM THE USE EFFECT BLOCK", userData.user._id)
                if (userData) {
                    setLocalData(userData.user._id)
                    setLocalToken(userData.accessToken);
                } else {
                    console.log("PROBLEM GETTING THE USER ID")
                }
            }
        }
        fetchLocalData()
    }, [localToken])

    // console.log("USER TOKEN", localToken)
    // console.log('USER ID', localData)
    // const courseId = courseID[1].courseId
    // console.log('COURSE ID', courseID)
    useEffect(() => {

        // if () { 

        // }
        //CHECK
        if (localData) {
            function assessment() {
                axios.get(`https://iqstore.herokuapp.com/v1/assessments/users/${localData}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localToken}`
                        }
                    })
                    .then((res) => {
                        setLoading(false)
                        if (res) {

                            // console.log("COURSE DATA FROM THE QNDA ASSESSMENT EFFECT", res.data.data.assessments[1].courseId)
                            setCourseID(res.data.data.assessments[0].courseId)
                            // console.log("THE ASSESSMENT DATA", res.data.data.assessments)
                        }
                    })
                    .catch((error) => {
                        // console.error("ASSESSMENT DATA", error)
                    })
            }
            assessment()

        }
    }, [localData])

    useEffect(() => {
        //CHECK
        async function getAssessAns() {

            // const ID = "5fe751605960ee9cd42a5244"
            // console.log("THE COURSEID IN USEEFFECT BLOCK", ID)
            // const url = `${ process.env.REACT_APP_API }/v1/questionsAnswers/courses/${ID}/questions`
            await axios.get(`https://iqstore.herokuapp.com/v1/questionsAnswers/questions?pageIndex=${currentPage}`,
                {
                    headers: {
                        'Authorization': `Bearer ${localToken}`
                    }
                })
                .then((res) => {
                    setLoading(false)
                    // console.log("QUESTION DATA", res.data.data.questions[0].author)
                    // setLikes(res.data.data.question_list)

                    setQuestionState(res.data.data.questions)
                    setPageNumber(res.data.data.totalPages)
                    setTotalQuestions(res.data.data.totalQuestions)
                    setQuestionsPerPage(res.data.data.totalQuestionsPerPage)
                    setCurrentPage(res.data.data.currentPage)
                    // console.log("QUESTION STATE", res.data.data.questions[0])
                })
                .catch((error) => {
                    console.error("QUESTION DATA", error)
                })
        } getAssessAns()

    }, [localToken, reset, likeReload, currentPage])

    const handleClick = (id) => {
        if (like == false) {
            LikePost(id)
            setLike(!like)

        } else if (like == true) {
            disLikePost(id)
            setLike(!like)
        }
        
    }
    const LikePost = (id) => {
        // console.log("Post clicked", id)
        axios({
            method: "PUT",
            url: `https://iqstore.herokuapp.com/v1/questionsAnswers/questions/${id}/like`,
            headers: {
                'Authorization': `Bearer ${localToken}`
            },
            data: { localData }
        }).then((res) => {
            // console.log("LIKE QUESTION".res)
            setLikesReload("reload")
        }).catch((err) => {
            // console.log("ERROR LIKE QUESTION".err)
        })
    }

    const disLikePost = (id) => {
        console.log("Post clicked", id)
        axios({
            method: "PUT",
            url: `https://iqstore.herokuapp.com/v1/questionsAnswers/questions/${id}/unlike`,
            headers: {
                'Authorization': `Bearer ${localToken}`
            },
            data: { localData }
        }).then((res) => {
            // console.log("DISLIKE QUESTION".res)
            setLikesReload("reloadDislike")
        }).catch((err) => {
            // console.log("ERROR LIKE QUESTION".err)
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setValues({ ...values, buttonText: "Sending..." });
        console.log({
            title,
            description
        });
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/v1/questionsAnswers/courses/${courseID}/questions`,
            data: { title, description },

            headers: {
                'Authorization': `Bearer ${localToken}`
            }

        }).then((res) => {
            console.log("FROM CREATE QUESTION", res)
            setValues({ ...values, buttonText: "Sent" })
            setReset("reset")
            // window.location.reload(true)

        })
            .catch((error) => {
                setValues({ ...values, buttonText: "Sending failed" })

                console.error("CREATE QUESTION ERROR", error)
            })
    }

    const pageHandleChange = (event, value) => {
        setCurrentPage(value);
    };

    // console.log("QUESTION STATE", questionState)
   const filterSearch = questionState.filter(s => (s.title.toLowerCase().includes(searchInput.toLowerCase())))
    return (
        <Grid>
            <Grid container className={classes.button} sm={12}>
                <Grid item sm={4}>
                    <input name="" className={classes.inputSearch} id="" placeholder="Search by Question" type="text" onChange={handleSearch} value={searchInput} />

                    {/* <Button className={classes.Buttonegde2} variant="contained" startIcon={<ArrowDropDownIcon />}>Select forum by course</Button> */}
                </Grid>

                <Grid item sm={3}>
                    {/* <Button className={classes.Buttonegde4} variant="contained" startIcon={<ArrowDropDownIcon />}><div className={classes.text}>Go Back</div></Button> */}
                </Grid>

                <Grid item sm={1}>
                    <Button className={classes.Buttonegde2} variant="contained"><div className={classes.text}>Filter</div></Button>
                </Grid>
                <Grid item sm={1} />
                <Grid item sm={3}>
                    <Button className={classes.Buttonegde3} variant="contained" startIcon={<QuestionAnswerIcon />}><div className={classes.text2} onClick={() => { setOpen(true) }}>Ask Question</div></Button>
                </Grid>
                <Grid className={classes.button}>
                    <Typography variant="body1" color="primary">Forum:<span className={classes.textColor}>Andriod development using</span></Typography>
                </Grid>
                <Modal isOpen={open} onRequestClose={() => { setOpen(false) }}
                    style={
                        {
                            overlay: {
                                background: "rgba(255, 255, 255, 0.5)",
                                backdropFilter: "blur(5px)",
                                border: "none",
                            },
                            content: {
                                color: "grey",
                                fontWeight: "bold",
                                width: "70%",
                                textAlign: "center",
                                top: '50%',
                                left: '50%',
                                right: '50%',
                                bottom: 'auto',
                                marginRight: '-50%',
                                borderRadius: "30px",
                                transform: 'translate(-50%, -50%)'
                            }
                        }
                    }
                >
                    {/* <ModalForm /> */}
                    <Grid container xs={12}>
                        <Grid item xs={false} md={1} />
                        <Grid item xs={12} md={7} >
                            <form style={Form} noValidate autoComplete="off" >
                                <Typography variant="h5">Ask a Question</Typography>
                                <input
                                    id="title"
                                    type="text"
                                    placeholder="Title"
                                    required
                                    style={Input}
                                    onChange={handleChange("title")}
                                    value={title}
                                />
                                <textarea
                                    id="description"
                                    type="text"
                                    placeholder="Description"
                                    required
                                    style={Input2}
                                    onChange={handleChange("description")}
                                    value={description}
                                />
                                <Button onClick={onSubmit} endIcon={<SendIcon />} className={classes.buttonA}>
                                    {buttonText}
                                </Button>
                            </form>
                        </Grid>
                        <Grid item xs={0} md={3} />
                    </Grid>

                    {/* <h1>Hello my guys</h1> */}
                    <div><Button onClick={() => { setOpen(false) }} endIcon={<CloseIcon />} className={classes.closeButton}></Button></div>
                </Modal>

                {/* QUESTION AND ANSWER STATE */}
                {/* <QndAState /> */}
                <>
                    {loading ? <Grid container sm={12} className={classes.loadingColor}>
                        <Typography variant="body3" color="primary" className={classes.loading}>Fetching Data

        </Typography><Loader type="TailSpin" color="#3e23ff" height={20} width={30} />
                    </Grid> : filterSearch.map((e) => {

                        // console.log("ALL COURSE ID", e._id)
                        return <Grid container sm={12} className={classes.button} key={e._id}>

                            <Grid item container sm={12} className={classes.colorBackground} >
                                <Grid item container sm={12} className={classes.textMargin}>
                                    <Grid item sm={12} >
                                        <List className={classes.cardBackground} >
                                            <Link to="/settings" className={classes.link} disableRipple="true">
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={Face}
                                                            className={classes.large}
                                                        />
                                                    </ListItemIcon>
                                                    <Grid item sm={12} disableFocusRipple={true}>
                                                        <Grid item sm={6}>
                                                            <Typography>{e.author.name}</Typography>
                                                        </Grid>
                                                        <Grid item sm={0} />
                                                        <Grid item sm={6}>
                                                            <Typography variant="body2"><Moment fromNow ago>{e.datePosted}</Moment> <span className={classes.ago}>ago</span></Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </Grid>
                                    <Grid >
                                        <Grid item sm={12}>
                                            <Typography variant="h6" style={{ fontWeight: "700" }}>{e.title}</Typography>
                                        </Grid>

                                        <Grid item sm={12}>
                                            <Typography variant="h7">
                                                {e.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>


                                    <Grid item container sm={12} className={classes.space}>
                                        <Grid item sm={1} >

                                            {/* <Button centerRipple={true} className={classes.white}><ThumbUpAltIcon fontSize="small" ></ThumbUpAltIcon></Button> */}
                                            <Button disableRipple="true" style={{ color: e.likes.length > 0 ? "#fe3266" : "grey" }} onClick={() => (handleClick(e._id))} disableTouchRipple={true} disableRipple={true} startIcon={<ThumbUpAltIcon />}><Typography className={classes.answerFont}> <span className={classes.black}>{e.likes.length}</span></Typography></Button>
                                            {/* <Button focusRipple={true} style={{ color: e.likes > 0 ? "red" : "black" }} onClick={() => (LikePost(e._id) === true ? LikePost(e._id) : disLikePost(e._id))} disableTouchRipple={true} disableRipple={true} startIcon={<ThumbUpAltIcon />}><Typography className={classes.answerFont}> <span className={classes.black}>{e.likes}</span></Typography></Button> */}

                                            {/*style={{ color: changeLikeColor }} */}
                                            {/* <Button className={classes.answerButton} startIcon={<QuestionAnswerIcon />} onClick={() => { setAnswer(true) }}><Typography className={classes.answerFont}>{e.numberOfAnswers} Answer</Typography></Button> */}


                                            {/* <Button centerRipple={true} className={classes.white}><ThumbUpAltIcon fontSize="small" ></ThumbUpAltIcon></Button> */}

                                            {/* <Button focusRipple={true} style={{ color: e.likes > 0 ? "red" : "black" }} onClick={() => (disLikePost(e._id))} disableTouchRipple={true} disableRipple={true} startIcon={<ThumbDownAltIcon />}><Typography className={classes.answerFont}> <span className={classes.black}>{e.likes}</span></Typography></Button> */}
                                            {/*style={{ color: changeLikeColor }} */}
                                            {/* <Button className={classes.answerButton} startIcon={<QuestionAnswerIcon />} onClick={() => { setAnswer(true) }}><Typography className={classes.answerFont}>{e.numberOfAnswers} Answer</Typography></Button> */}
                                        </Grid>
                                        <Grid item sm={11}>
                                            {/* <Button className={classes.answerButton} startIcon={<QuestionAnswerIcon />} onClick={() => questionID(e._id)}><Typography className={classes.answerFont}>200 Answer</Typography></Button> */}
                                            <div className={classes.root2}>
                                                <Accordion square={false} style={{ border: "none", borderRadius: "20px" }}>
                                                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                        <Button
                                                            className={classes.heading}
                                                            startIcon={<CommentOutlinedIcon />}
                                                        >
                                                            {e.answers.length} {e.answers.length > 1 ? "Answers" : "Answer"} 
                                                    </Button>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
                                                        <ModalAnswerQuestionForm style={{ width: "100%" }} queID={e._id} getCourseID={courseID} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </Grid>
                                        {/* <Grid item sm={9} /> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}

                </>
            </Grid>
            <Grid
                item
                xs={12}
                container

                style={{ marginTop: "20px", width: "100%", fontSize: "10px" }}
            >
                <div className={classes.pageRoot}>
                    <Pagination
                        count={pageNumber}
                        // variant="outlined"
                        // shape="rounded"
                        onChange={pageHandleChange}
                        style={{ width: "100%" }}
                    />
                </div>
                <div className={classes.pageRoot}>
                    <Typography style={{ margin: "0 0 0 10%", width: "100%" }}>
                        Page: {currentPage}, {currentPage}-{pageNumber} of {totalQuestions} Questions
              </Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default QNdA