import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CommentIcon from '@material-ui/icons/Comment';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DynamicComponent from "../Components/Settings/DynamicComponent"
import { DashBoardHeader } from "../Layout/InputButton";
import DashboardNav from "../Layout/DashboardNav";


import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "20px",
        width: "20%",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    bg: {
        backgroundColor: "#f2f2f2",
    },
    content: {
        margin: "1.25rem",
    },
    Buttonegde: {
        borderRadius: "1rem",

        padding: "10px 40px",
        backgroundColor: "#fff",
        color: "#3e23ff",
        "&:hover": {
            backgroundColor: "#3e23ff",
            color: "#fff",
        }
    },
    Buttonegde2: {
        borderRadius: "1.25rem",
        width: "90%",
    },
    body2: {
        fontSize: "0.85rem",
        fontWeight: "bold",
        // backgroundColor: "#3e23ff",
        // color: "#fff"
    }
}));


function Settings() {
    const classes = useStyles();
    const [user, changeUser] = useState("profile");

    return (
        <Grid container xs={12} className={classes.bg}>
            <DashboardNav />
            {/*/#.....*/}
            <Grid item xs={10} container className={classes.content}>
                <Grid
                    item
                    container
                    style={{
                        width: "100%",
                        padding: "20px",
                    }}
                >
                    <DashBoardHeader />
                    <Grid item className={classes.root} sm={10}>
                        <ButtonGroup size="small" aria-label="small outlined button group" className={classes.Buttonegde2}>
                            <Button className={classes.Buttonegde} startIcon={<SettingsOutlinedIcon />} variant="contained" onClick={() => changeUser("profile")}><Typography inherit="left" className={classes.body2}>Profile</Typography></Button>
                            <Button className={classes.Buttonegde} variant="contained" startIcon={<NewReleasesIcon />} onClick={() => changeUser("billing")}><Typography className={classes.body2}>Billing</Typography></Button>
                        </ButtonGroup>
                    </Grid>
                    <DynamicComponent user={user} />
                </Grid>
            </Grid>
            {/*...... */}
            <Grid item xs={1} />
        </Grid>
    );
}

export default Settings;