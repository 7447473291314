const AOIdata = [{
    _id: 1,
    AOI: "Philosophical Foundations of Education"
}, {
    _id: 2,
        AOI: "Sociology of Education"
}, {
    _id: 3,
        AOI: "Social mobility and education"
}, {
    _id: 4,
        AOI: "Educational Psychology"
}, {
    _id: 5,
        AOI: " Learner and learning"
}, {
    _id: 6,
        AOI: "Teacher and classroom"
}, {
    _id: 7,
        AOI: "Information and Communication Technology (ICT)"
}, {
    _id: 8,
        AOI: "Guidance and Counselling"
}, {
    _id: 9,
        AOI: " Educational Planning and Administration"
}, {
    _id: 10,
        AOI: " Economics of Education"
}, {
    _id: 11,
        AOI: "Research in education"
}, {
    _id: 12,
    AOI: "option12"
}, {
    _id: 13,
    AOI: "option13"
}, {
    _id: 14,
    AOI: "option14"
}, {
    _id: 15,
    AOI: "option15"
},
{
    _id: 16,
    AOI: "option16"
}]


export default AOIdata