import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CardComponent2 } from "../Layout/Card";
import { DashBoardHeader } from "../Layout/InputButton";
// import axios from "axios";

// import SewingImg from "../images/sewing.png";

import DashboardNav from "../Layout/DashboardNav";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#32CD32",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#f2f2f2",
    // height: "100vh",
  },
  content: {
    // padding: "10px",
    margin: "20px",
  },
  logout: {
    color: "#fff",
    textDecoration: "none",
  },
  input: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "50px",
    padding: "20px",
    outline: "none",
    marginTop: "10px",
    width: "40%",
    position: "relative",
    boxShadow: "6px 7px 16px -1px rgba(0,0,0,0.53)",
  },

  button: {
    backgroundColor: "#3e23ff",
    color: "#fff",
    width: "15%",
    padding: "15px",
    border: "none",
    borderRadius: "50px",
    margin: "10px",
    position: "relative",
    left: "-70px",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
  },

  img: {
    width: "16rem",
    maxHeight: "11rem",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  },

  iconButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  AssignIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },

  continueButton: {
    width: "80%",
    padding: "0.6rem",
    borderRadius: "50px",
    left: 0,
    backgroundColor: "#e0dbff",
    color: "#3E24FB",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      fontSize: "0.7rem",
    },
  },

  closedButton: {
    width: "20%",
    padding: "6px",
    borderRadius: "50px",
    position: "relative",
    margin: "10px",
    left: 0,
    backgroundColor: "#ffcccb",
    color: "red",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      left: "-20px",
      fontSize: "0.7rem",
    },
  },

  cardCourse: {
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },

  viewCourse: {
    padding: "0.813rem",
    marginBottom: "1.25rem",
    borderRadius: "3.125rem",
    width: "13.75rem",
    backgroundColor: "#3e23ff",
    border: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#255DE2",
      color: "#fff",
    },
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  viewMore: {
    padding: "0.938rem",
    borderRadius: "3.125rem",
    width: "12.5rem",
    backgroundColor: "#fff",
    border: "2px solid #3e23ff",
    marginTop: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  stats: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "1.875rem",
  },

  statsPaper: {
    backgroundColor: "#C0C0C0",
    width: "22rem",
    padding: "1.26rem",
    borderRadius: "0.625rem",
    marginRight: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "18rem",
      padding: "0.5rem",
      marginRight: "0.3rem",
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: "12rem",
    // },
  },

  completedBtn: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  },

  statsHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },

  cardComponent: {
    borderLeft: "1px solid #C0C0C0",
    paddingLeft: "6.25rem",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "0.5rem",
    },
  },
  paperCard: {
    padding: "20px",
    borderRadius: "10px",
    position: "relative",
    width: "100%",
  },

  certPaper: {
    backgroundColor: "#C0C0C0",
    width: "15rem",
    padding: "1.25rem",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      padding: "0.5rem",
    },
  },

  certBtn: {
    padding: "1rem",
    borderRadius: "3.125rem",
    width: "20rem",
    backgroundColor: "#3e23ff",
    border: "none",
    color: "#fff",
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  classCard: {
    marginTop: "40px",
    width: "100%",
  },

  cardStyle: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

function Dashboard() {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [stats, setStats] = useState({});
  const [viewAll, setViewAll] = useState(false);
  const [view, setView] = useState("View All Courses");

  useEffect(() => {
    async function getData() {
      if (window !== "undefined") {
        const uData = await localStorage.getItem("user");
        const userData = (await uData) ? JSON.parse(uData) : "";
        // console.log("FROM THE USE EFFECT BLOCK", userData.user._id)
        setCourses(userData.courses);
        console.log("Data", userData);
        setStats(userData.assessments);
        console.log("User", courses);
      }
    }
    getData();
  }, []);

  const handleViewAll = () => {
    setViewAll(!viewAll);
    if (!viewAll) {
      setView("View Less Courses");
    } else {
      setView("View All Courses");
    }
  };

  return (
    <>
      <Grid container xs={12} className={classes.bg}>
        <DashboardNav />
        <Grid item container xs={10} className={classes.content}>
          <Grid item container className={classes.classCard}>
            <DashBoardHeader />
            {courses.map((course, index) => {
              if (index > 0 || viewAll === true) {
                return (
                  <Grid item className={classes.classCard} key={course.id}>
                    <Paper elevation={3} style={{ padding: "20px" }}>
                      <Grid
                        item
                        container
                        xs={12}
                        className={classes.cardStyle}
                      >
                        <Grid item xs={12} md={3}>
                          <img
                            src={course.coverImage.url}
                            alt="Course Img"
                            className={classes.img}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          md={7}
                          direction="column"
                          style={{ paddingLeft: "20px" }}
                        >
                          <Grid item>
                            <Button className={classes.closedButton}>
                              In Progress
                            </Button>
                          </Grid>
                          <Grid item xs={12} className={classes.cardCourse}>
                            <Typography variant="h6">{course.title}</Typography>
                          </Grid>
                          <Grid xs={12} item className={classes.cardCourse}>
                            <Typography>Module 10</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.cardCourse}
                            style={{ display: "flex" }}
                          >
                            <DateRangeIcon />
                            <Typography>
                              Session: 12th may to 3rd December
                            </Typography>
                          </Grid>
                          <Grid xs={12} item className={classes.cardCourse}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className={classes.iconButton}
                        >
                          <AssignmentIcon className={classes.AssignIcon} />
                          <Button className={classes.continueButton}>
                            Continue
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              }
            })}
            <Grid item container className={classes.stats}>
              <Grid item xs={12} md={6} style={{ width: "50%" }}>
                <Button className={classes.viewCourse} onClick={handleViewAll}>
                  {view}
                </Button>
                <Paper className={classes.paperCard}>
                  <Typography variant="h5">Stats</Typography>
                  <Paper elevation={0} style={{ display: "flex" }}>
                    <Paper className={classes.statsPaper}>
                      <Paper
                        elevation={0}
                        style={{
                          display: "flex",
                          backgroundColor: "#C0C0C0",
                        }}
                      >
                        <div>
                          <Button
                            startIcon={
                              <AssignmentTurnedInIcon
                                style={{ color: "#00ff00" }}
                              />
                            }
                            className={classes.completedBtn}
                          >
                            Completed
                          </Button>
                          <Typography
                            variant="h1"
                            className={classes.statsHeading}
                          >
                            {stats.completed}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            startIcon={
                              <AssignmentTurnedInIcon
                                style={{ color: "#ff0000" }}
                              />
                            }
                            style={{ color: "#ff0000" }}
                            className={classes.completedBtn}
                          >
                            Pending
                          </Button>
                          <Typography
                            variant="h1"
                            className={classes.statsHeading}
                          >
                            {stats.pending}
                          </Typography>
                        </div>
                      </Paper>
                      <Typography variant="body1">
                        Assignments({stats.total})
                      </Typography>
                    </Paper>
                    <Paper className={classes.certPaper}>
                      <Paper
                        elevation={0}
                        style={{ backgroundColor: "#C0C0C0" }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Button className={classes.certBtn}>
                              Certificate
                            </Button>
                            <NewReleasesIcon
                              style={{
                                fontSize: "2.5rem",
                                color: "#e2af80",
                              }}
                              className={classes.completedBtn}
                            />
                          </div>
                          <Typography
                            variant="h1"
                            className={classes.statsHeading}
                          >
                            40
                          </Typography>
                        </div>
                      </Paper>
                      <Typography variant="body1">Certificate</Typography>
                    </Paper>
                  </Paper>
                </Paper>
              </Grid>
              <Grid item xs={12} md={5} className={classes.cardComponent}>
                <CardComponent2 />
                <Button className={classes.viewMore}>View More</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
}

export default Dashboard;
